import { Stack } from 'react-bootstrap';
import {
  Button,
  FormattedMessageDiv,
  LoadingAnimation,
} from '@odmonorepo/core-components';

import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CalculatorInputSlider } from '../Calculator/CalculatorInputSlider';
import { CalculatorPaymentFrequency } from '../Calculator/CalculatorPaymentFrequency';
import { CalculatorTermSelection } from '../Calculator/CalculatorTermSelection';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { getPricingDetails, toPricingRequest, useDetailedOffers } from 'merchant-api/offer';
import { useSetAtom } from 'jotai';
import { disablePreview, pricedDetails } from '../../OfferConfig';
import { useApplication } from 'merchant-api/butterfly';

export const FullConfig = () => {
  const { watch } = useFormContext();
  const { tlOffers, customerData, isLoadingDetailedOffers, isErrorOffers } = useDetailedOffers();
  const { applicationData } = useApplication();
  const { getPricingError, getPricing, getPricingSending} = getPricingDetails();
  const data = watch("offer");
  const selectedOffer = tlOffers.find((offer) => offer.id === data?.offerId);

  const setDisabled = useSetAtom(disablePreview)
  const setPricing = useSetAtom(pricedDetails)
  const handlePricing = () => getPricing(toPricingRequest(selectedOffer, applicationData, customerData)).then(res =>setPricing({...res})).then(() => setDisabled(false))

  useEffect(() => {handlePricing()}, []);

  if (isLoadingDetailedOffers) return <Stack><LoadingAnimation className="mx-auto" /> </Stack>
  if (isErrorOffers) throw new Error('Error loading offers');
  if (getPricingError) throw new Error('Error getting pricing');
  
 

  return (
    <Stack className={combineClassNames("shadow-none px-3 pt-5 pb-sm-5 ps-sm-6", watch("offerPreview.show") ? "pe-sm-4" : "pe-sm-6")}>
        <FormattedMessageDiv
          className="fw-bold mb-4"
          id="offerConfig.full.calculator.heading"
        />
        <Stack gap={1}>
          <CalculatorInputSlider tlOffers={tlOffers} />
          <hr className="border-medium-gray" />
          <CalculatorTermSelection tlOffers={tlOffers} />
          <hr className="border-medium-gray" />
          <CalculatorPaymentFrequency/>
          <hr className="border-medium-gray" />
          <Button
            loading={getPricingSending}
            className="w-100 mb-4"
            onClick={() => handlePricing()}
          >
            <FormattedMessage id="calculator.calculate" />
          </Button>
        </Stack>
    </Stack>
  );
};
