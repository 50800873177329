import { PlaidMerchantInfoResponse } from "merchant-api/types";
import { useParties } from 'merchant-api/spiceroad';
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

export interface PlaidMerchantInfoResponseProps {
   isPlaidMerchantInfoResponseMutating: boolean;
   isPlaidMerchantInfoResponseError: any;
   plaidMerchantInfoResponse: PlaidMerchantInfoResponse;
   getPlaidMerchantInfo: Function;
}

export function getPlaidInfo(): PlaidMerchantInfoResponseProps {
   const apiUrl = import.meta.env['VITE_SPICEROAD_URL']
   const { isRenewal } = useParties();
   const { fetcher } = useSWRConfig();

   async function sendRequest(url) {
       return fetcher && fetcher(url);
   };

   const { data, error, isMutating, trigger} = useSWRMutation(isRenewal ? `${apiUrl}/v1/plaid/merchant-info` : null, sendRequest)
   return {
       isPlaidMerchantInfoResponseMutating: isMutating,
       isPlaidMerchantInfoResponseError: error,
       plaidMerchantInfoResponse: data as PlaidMerchantInfoResponse,
       getPlaidMerchantInfo: trigger
   }
}

export default getPlaidInfo;
