import { Stack } from 'react-bootstrap';
import {
  Card,
  FormCheckbox,
  FormInput,
  FormRadio,
  FormattedMessageDiv,
} from '@odmonorepo/core-components';
import * as yup from 'yup';

export const OwnerDetailsSchema = yup.object().shape({
  person: yup.object().shape({
    ownershipPct: yup.number().required().max(100).min(1),
    isPrimaryOwner: yup.boolean(),
  }),
  creditConsent: yup.boolean().oneOf([true]),
  eSignConsent: yup.boolean().oneOf([true]),
});

export const OwnerDetails = () => (
  <Card>
    <Stack gap={3}>
      <FormattedMessageDiv className="fw-bold" id={'renewalApp.ownerInfo'} />
      <FormInput name="person.ownershipPct" onKeyDown={e => !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'].includes(e.key) && !/[0-9]/.test(e.key) && e.preventDefault()}/>
      <FormRadio name="person.isPrimaryOwner" options={['true', 'false']} />
      <FormCheckbox name="creditConsent" 
        label={
           <FormattedMessageDiv id={`formCheckbox.creditConsent`} values={{
            terms: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/terms`}>{txt}</a>,
            credit: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/creditagreement`}>{txt}</a>}}/>
        }
        />
      <FormCheckbox name="eSignConsent" 
        label={
          <FormattedMessageDiv id={`formCheckbox.eSignConsent`} values={{
            consent: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/esignagreement`}>{txt}</a>}}/>
        } 
      />
    </Stack>
  </Card>
);

export default OwnerDetails;