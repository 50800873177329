import { useState, useEffect } from 'react';
import { LoanStep } from './LoanAppForm';
import { getBusiness, getLDR, getRenewal, useLoan, useParties, usePersons } from 'merchant-api/spiceroad';
import { useApplication } from 'merchant-api/butterfly';

export const loanStep = (): {
  step: LoanStep | undefined;
  stepLoading: boolean;
  stepError: boolean;
  showRenewal: boolean;
  applicationId: string
} => {
  const { applicationData, isErrorApplication, isLoadingApplication, applicationId } = useApplication();
  const { hasOpenBankRequest, ldrError, ldrLoading } = getLDR();
  const { activeTL, loanError } = useLoan();
  const { renewal } = getRenewal();
  const {optimizely, isLoadingParties} = useParties();
  const [step, setStep] = useState<number>();

  useEffect(() => {
    if (!isLoadingApplication && !ldrLoading && !isLoadingParties) {
      switch (applicationData?.status) {
        case 'SUBMITTED':
            setStep(LoanStep.CONSENT);
          break;
        case 'PREPROCESSED':
          setStep(
            hasOpenBankRequest
              ? optimizely?.plaidActiveConnectionEnabled === true && applicationData?.type === 'RENEWAL'
                ? LoanStep.BANK_CONFIRMATION
                : LoanStep.BANK_INFO
              : LoanStep.RECEIVED
          );
          break;
        case 'REROUTED_LEGACY':
          setStep(LoanStep.RECEIVED);
          break;
        case 'CLOSED_LOST':
        case 'CLOSED_WON':
        case 'NOT_APPROVED':
          setStep(LoanStep.DECLINED);
          break;
        case 'APPROVED':
          setStep(LoanStep.PW_SET);
          break;
        default:
          window.location.pathname === '/renewal' || applicationData?.type === 'RENEWAL' ? setStep(LoanStep.BUSINESS_STEP) : setStep(LoanStep.FINANCE_STEP);
          break;
      }
    }
  }, [applicationData, ldrLoading, isLoadingApplication, isLoadingParties, optimizely, hasOpenBankRequest]);

  return {
    step,
    stepLoading: step === undefined,
    stepError: isErrorApplication || ldrError || loanError,
    showRenewal: applicationData?.status !== 'APPROVED' && activeTL?.percentagePaidDown >= 25 && renewal?.termLoanEligible,
    applicationId: applicationId
  };
};

export const loanDefaultValues = () => {
  const {
    businessDetails,
    businessAddress,
    businessPhoneNumber,
    isBusinessLoading,
  } = getBusiness();
  const { person, personAddress, personPhoneNumber, isLoading } = usePersons();
  const { applicationData, isLoadingApplication } = useApplication();

  return {
    loading: isBusinessLoading || isLoading || isLoadingApplication,
    defaultValues: {
      financeNeeds: {
        desiredAmountToBorrow: applicationData?.data?.desiredAmountToBorrow,
        desiredFundsAvailability:
          applicationData?.data?.desiredFundsAvailability,
        desiredUseOfFunds: applicationData?.data?.desiredUseOfFunds,
      },
      business: {
        id: businessDetails?.id,
        legalName: businessDetails?.legalName,
        address: {
          lineOne: businessAddress?.lines[0],
          ...businessAddress,
        },
        phone: businessPhoneNumber,
        dba: String(businessDetails?.doingBusinessAs !== undefined),
        dbaName: businessDetails?.doingBusinessAs,
        legalEntityType: businessDetails?.legalEntityType,
      },
      person: {
        id: person?.id,
        firstName: person?.firstName,
        lastName: person?.lastName,
        address: {
          lineOne: personAddress?.lines[0],
          ...personAddress,
        },
        homePhone: personPhoneNumber,
      },
    },
  };
};
