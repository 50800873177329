import {
  BlueBlob,
  Card,
  FormattedMessageDiv,
  FormInput, FormSelect,
  WizardNav,  FormMoneyInput
} from '@odmonorepo/core-components';
import * as yup from 'yup';
import {Col, Row, Stack} from 'react-bootstrap';
import React from 'react';
import {makeOptions} from "@odmonorepo/odfunctions";

export const FinanceNeedsSchema = yup.object().shape({
  financeNeeds: yup.object().shape({
    desiredAmountToBorrow: yup.number().required(),
    desiredFundsAvailability: yup.string().required(),
    desiredUseOfFunds: yup.string().required(),
  }),
});

export const FinanceNeeds = () => (
  <Stack gap={5}>
    <BlueBlob>
      <FormattedMessageDiv className="h3" id="financeNeeds.step.1" />
    </BlueBlob>
    <Card>
      <Stack gap={3}>
        <Row xs={1} md={2} className="g-3">
          <Col>
            <FormMoneyInput name="financeNeeds.desiredAmountToBorrow" />
          </Col>
          <Col>
            <FormSelect
              name="financeNeeds.desiredFundsAvailability"
              options={makeOptions('financeNeeds.desiredFundsAvailability', 6)}
            />
          </Col>
        </Row>
        <FormInput name="financeNeeds.desiredUseOfFunds" />
      </Stack>
    </Card>
    <WizardNav continueOnly schema={FinanceNeedsSchema} />
  </Stack>
);

export default FinanceNeeds;
