/// <reference types="vite/client" />
import useSWR from 'swr';
import useParties from '../useParties/useParties';

export interface UseLoanProps {
  activeTL: any;
  loanError: any;
  loanLoading: boolean;
}

export function useLoan(): UseLoanProps {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL']

  const { hasTL } = useParties();
  const { data, error, isLoading} = useSWR(hasTL ? apiUrl + "/v1/loans/" + hasTL : null);
  
  return {
    activeTL: data?.status === 'ACTIVE' ? data : null,
    loanError: error,
    loanLoading: isLoading,
  };
}

export default useLoan;
