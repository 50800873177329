import { Application } from 'merchant-api/types';
import useSWR, { KeyedMutator } from 'swr';

export interface UseApplicationProps {
  isLoadingApplication: boolean;
  applicationData: Application;
  isErrorApplication: any;
  opportunityId: string;
  applicationId: string;
  partyId: string;
  isPartnerChannel: Boolean;
  mutate: KeyedMutator<Application>,
  refetchApplicaton: () => Promise<any>
}

export function useApplication(): UseApplicationProps {
  const apiUrl = import.meta.env['VITE_BUTTERFLY_URL']
  const { data, error, isLoading, mutate } = useSWR(`${apiUrl}/v3/orchestration/current_application`, {revalidateOnMount: true, errorRetryCount: 0})
  return {
   applicationData: (error || isLoading) ? undefined : Object.keys(data).length ? data : null,
    opportunityId: data?.metadata?.sfOpportunityId,
    applicationId: data?.id,
    partyId: data?.sourcePartyId,
    isLoadingApplication: isLoading,
    isErrorApplication: error,
    isPartnerChannel: data?.metadata?.channelCode === 'PARTNER_ISO',
    mutate,
    refetchApplicaton: () => mutate()
  }
}


export default useApplication;
