import {useSWRConfig} from 'swr';
import useSWRMutation from "swr/mutation";
import useApplication from '../useApplication/useApplication';
import { useEffect } from 'react';


export interface UpdateAppStatusProps {
  response: any;
  updating: boolean;
  updateError: string;
  updateStatus: any;
}

export function updateAppStatus(): UpdateAppStatusProps {
  const apiUrl = import.meta.env['VITE_BUTTERFLY_URL']
  const { fetcher, mutate } = useSWRConfig();
  const { applicationId } = useApplication();
  
  async function sendRequest(url, { arg }) {
    if (applicationId){
      return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify({...arg})});
    }
    else {
      const res = await mutate(`${apiUrl}/v3/orchestration/current_application`);
      return fetcher && res?.id && fetcher(`${apiUrl}/v2/orchestration/${res?.id}/update_status`, {method: 'POST', body: JSON.stringify({...arg})});
    }
  };

  const { data, error, trigger, isMutating } = useSWRMutation(`${apiUrl}/v2/orchestration/${applicationId}/update_status`, sendRequest);

  return {
    response: data,
    updating: isMutating,
    updateError: error,
    updateStatus: trigger
  }
}

export default updateAppStatus;
