import { BankIcon, Button, Card, ErrorCard, FormattedMessageDiv, LoadingCard, SecurityIcon } from '@odmonorepo/core-components';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Badge, Col, Collapse, Stack } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess, PlaidLinkOnSuccessMetadata, PlaidLinkError, PlaidLinkOnExit, PlaidLinkOnExitMetadata } from 'react-plaid-link';
import PlaidSuccessCard from './PlaidSuccessCard';
import { useWizard } from 'react-use-wizard';
import { useApplication } from 'merchant-api/butterfly';
import { useParties, usePlaid } from 'merchant-api/spiceroad';

interface PlaidProps { next?: boolean }
export function PlaidCard({ next }: PlaidProps) {
  const intl = useIntl();
  const { optimizely, isLoadingParties} = useParties();
  const { applicationData, isLoadingApplication, isErrorApplication } =  useApplication();
  const { getClientInfo, trackPlaid, isLoading } = usePlaid(applicationData?.metadata?.sfOpportunityId);
  const [errorAlert, setErrorAlert] = useState(false);
  const [linkToken, setLinkToken] = useState('');
  const [plaidSuccess, setPlaidSuccess] = useState(false);
  const { nextStep } = useWizard();

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      setErrorAlert(false);
      trackPlaid({
        callback: 'onSuccess',
        metadata: JSON.stringify(metadata),
        publicToken: public_token,
        eventName: 'OPEN',
        error: '',
        variation: ''
      }).then(track => track?.ok && next ? nextStep() : setPlaidSuccess(true))
        .catch(() => {setErrorAlert(true)})
  },[]);

  const onExit = useCallback<PlaidLinkOnExit>(
    async (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      setLinkToken('');
      if (error) {
        setErrorAlert(true);
        await trackPlaid({
          callback: 'onExit',
          metadata: JSON.stringify(metadata),
          publicToken: '',
          eventName: 'EXIT',
          error: error ? JSON.stringify(error) : '',
          variation: ''
        });
      }
    }, []
  );
  const config: PlaidLinkOptions = { onSuccess, onExit, token: linkToken};

  const { open } = usePlaidLink(config);

  const launchPlaid = async () => {
    setErrorAlert(false);
    const clientInfo = await getClientInfo();
    clientInfo?.link_token ? setLinkToken(clientInfo?.link_token) : setErrorAlert(true);
  };

  useEffect(() => {
    document.body.style.overflow = 'auto';
    !plaidSuccess && !errorAlert && linkToken && open();
  }, [linkToken, launchPlaid, errorAlert, plaidSuccess]);

  if (isErrorApplication) return <ErrorCard name={intl.formatMessage({ id: 'loading.error' })} />;
  if (isLoadingApplication || isLoadingParties) return <LoadingCard />;

  return (
    <Stack>
      {!plaidSuccess ? (
        <Card>
          <Collapse in={errorAlert}>
            <div>
              <Alert variant="danger">
                Error connecting bank. Please try again later
              </Alert>
            </div>
          </Collapse>
          <Stack direction='horizontal' className="justify-content-center" gap={3}>
            <FormattedMessageDiv id={`plaid.title.M3.${optimizely?.eceRenewalMilestone3 ? 'true' : 'false'}`} className="text-center h4" />
            {optimizely?.eceRenewalMilestone3 && <Badge bg="info" className="fs-7 p-2 mb-2">Recommended</Badge>}
          </Stack>
          <FormattedMessageDiv
            id="plaid.description"
            className="my-5"
            values={{
              sup: (txt: any) => {
                return <sup>{txt}</sup>;
              },
              link: (txt: any) => {
                return <a href={`https://my.plaid.com`}>{txt}</a>;
              },
            }}
          />
          <Stack className="flex-column flex-md-row" gap={4}>
            <Col className="d-flex shadow-none bg-body p-5 rounded align-items-center">
              <SecurityIcon />
              <FormattedMessageDiv id="plaid.icon.text1" className="ms-6 fs-7"/>
            </Col>
            <Col className="d-flex shadow-none bg-body p-5 rounded align-items-center">
              <BankIcon />
              <FormattedMessageDiv id="plaid.icon.text2" className="ms-6 fs-7 h-100"/>
            </Col>
          </Stack>
          <div className="w-100 d-flex justify-content-center my-5">
            <Button onClick={() => launchPlaid()} loading={isLoading}>
              <FormattedMessageDiv id="plaid.button" />
            </Button>
          </div>
          <FormattedMessageDiv
            className="text-center"
            id="plaid.customer.service"
            values={{
              tel: (txt: any) => {
                return <a href={`tel:${txt}`}>{txt}</a>;
              },
            }}
          />
        </Card>
      ) : (
        <PlaidSuccessCard />
      )}
    </Stack>
  );
}

export default PlaidCard;
