import { Alert, Col, Row, Stack } from 'react-bootstrap';
import { Button, Card, ExclamationCircle, FormattedMessageDiv, FormInput, GreenCheck, useWizardFormContext, WizardForm } from '@odmonorepo/core-components';
import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import { updatePassword } from 'merchant-api/spiceroad';

export const passwordCriteria = [
  {
    label: '8 – 30 characters',
    test: (password) => password?.length >= 8 && password?.length <= 30,
  },
  {
    label: 'At least one number (0 - 9)',
    test: (password) => /\d/.test(password),
  },
  {
    label: 'At least one special character',
    test: (password) => /[!#@$%^&*()_+~\-=`{}[\]:";'<>?,./]/.test(password),
  },
  {
    label: 'Upper and lowercase letters',
    test: (password) => /[A-Z]/.test(password) && /[a-z]/.test(password),
  },
];

const passwordValidation = yup.string().test(
  'password-criteria', 'Password does not meet requirements',
  (value) => passwordCriteria.every((criterion) => criterion.test(value))
);

export const PasswordSchema = yup.object().shape({
  account: yup.object().shape({
    pw: passwordValidation.required('Password is required'),
    confirmPw: yup.string().required('Please confirm your password').oneOf([yup.ref('pw')], 'Passwords do not match'),
  }),
});

export const CreateAccountForm = () => {
  const { triggerPartial } = useWizardFormContext();
  const { watch } = useFormContext();
  const password = watch('account.pw');
  const { getValues } = useFormContext();
  const { triggerUpdate, updateError, updateResult } = updatePassword();

  const handleContinue = async() => {
    const valid = await triggerPartial(PasswordSchema);
    if (valid) {
      const res = await triggerUpdate({
        currentPassword : '',
        newPassword: getValues().account.pw
      });
      console.log('result: ',res, updateError, updateResult);
    }
  }

  const requirements = (password) => {
    return (
      <Stack gap={2} className='mt-1 fs-7'>
        {passwordCriteria.map((criterion, i) => (
          <div key={i} className='d-flex'>
            <div className="px-2">
              {criterion.test(password) ? <GreenCheck width='14px'/> : <ExclamationCircle />}
            </div>
            <div className={` ${criterion.test(password) ? 'text-green' : 'text-red'}`}>
              {criterion.label}
            </div>
          </div>
        ))}
      </Stack>
    );
  };

  return (
    <Stack gap={5} className='py-5'>
      {updateError && <AlertMessage variant="danger" messageId="createAccount.error" />}
      {updateResult && !updateResult?.error && <AlertMessage variant="success" messageId="createAccount.success" />}
      <FormInput name="account.pw" type="password" useYupErrors />
      <FormInput name="account.confirmPw" type="password" useYupErrors />
      <Stack>
        <div className='fw-bold'>Password Requirements</div>
        <div>{requirements(password)}</div>
      </Stack>
      <Button onClick={handleContinue} className='mt-3'>{'Continue'}</Button>
    </Stack>
  );
};

export const CreateAccountCard = () => { 
  return (
    <WizardForm>
      <Stack className="m-auto col-md-6 col-12" style={{maxWidth: '500px'}}>
       <Card className="p-3">
          <FormattedMessageDiv id="createAccount.title" className="h3 mb-5 text-center" />
          <CreateAccountForm />
       </Card>
      </Stack>
    </WizardForm>
   );
}

const AlertMessage = ({variant, messageId}) =>
    <Alert variant={variant}>
      <FormattedMessageDiv id={messageId} />
    </Alert>