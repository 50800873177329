import { Application } from "merchant-api/types";
import { PricingRequest } from "./getPricingDetails";

export function toPricingRequest(selectedOffer, applicationData: Application, customerData): PricingRequest {
    return {
        applicationType: applicationData?.type,
        customerData: {
            businessCountry: customerData?.businessCountry,
            businessState: customerData?.businessState,
            legalEntity: customerData?.legalEntity,
        },
        loanSpecifics: [
            {
                centsOnDollar: selectedOffer?.pricedDetails.centsOnDollar,
                collectionType: "ACH",
                line: selectedOffer?.pricedDetails.lineAmount,
                oFeePercentage: selectedOffer?.pricedDetails.oFeePercentage,
                paymentFrequency: selectedOffer?.paymentFrequency,
                product: "TermLoan",
                productType: "TL",
                term: selectedOffer?.pricedDetails.term,
            },
        ],
    };
}