import { useWizard } from 'react-use-wizard';
import { useEffect } from 'react';
import { BlueBlob, ErrorPage, FormattedMessageDiv, LoadingAnimation } from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import PlaidCard from '../../Plaid/PlaidCard';
import PlaidDisclaimer from '../../Plaid/PlaidDisclaimer';
import StatementUploader from './StatementUploader';
import { createLDR, useParties, getMerchantProfile } from 'merchant-api/spiceroad';

export const BankInfoCard = () => {
  const { optimizely, isLoadingParties} = useParties();
  const { hasOpenBankRequest, ldrLoading, ldrError } = createLDR();
  const { isLoadingMerchantProfile, isErrorMerchantProfile, accountNumber, applicationType } = getMerchantProfile();
  const { nextStep } = useWizard();

  useEffect(() => {
    hasOpenBankRequest === false && nextStep();
  }, [hasOpenBankRequest]);

  if (ldrLoading || isLoadingParties || isLoadingMerchantProfile || hasOpenBankRequest === false)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto mt-7" />
      </Stack>
    );

  if (ldrError || isErrorMerchantProfile) return <ErrorPage />;

  const bankConfirmation = sessionStorage.getItem("bankConfirmation") === 'Yes';
  return (
    <Stack gap={5}>
      <BlueBlob>
        <FormattedMessageDiv className="h3" id="renewalApp.step.final" />
        {optimizely?.plaidActiveConnectionEnabled === true && applicationType === 'RENEWAL' && 
          <FormattedMessageDiv
          id={`loanApp.step.bankInfo.subheading.${bankConfirmation ? 'yes' : 'no'}`}
          className='text-center'
          values={{
            b: (txt: any) => <b>{txt}</b>,
            accountLastFour: (bankConfirmation ? accountNumber?.slice(-4) : null)
          }}
          />
        }
      </BlueBlob>
      <PlaidCard next />
      {optimizely?.eceRenewalMilestone3 && <StatementUploader />}
      <PlaidDisclaimer />
    </Stack>
  );
};

export default BankInfoCard;
