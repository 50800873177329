import {useWizard} from 'react-use-wizard';
import { LoanStep } from '../LoanAppForm';
import {useEffect} from 'react';
import {
  BankIcon,
  BlueBlob,
  FormattedMessageDiv, FormRadio,
  LoadingAnimation, WizardNav,
} from '@odmonorepo/core-components';
import {Card as BsCard, Card, Col, Row, Stack} from 'react-bootstrap';
import {useParties, getMerchantProfile, getPlaidInfo, createLDR, updateLDRs} from 'merchant-api/spiceroad';
import * as yup from "yup";
import { useFormContext } from 'react-hook-form';

export const BankConfirmationSchema = yup.object().shape({
  bankConfirmation: yup.object().shape({
    checkingAccount: yup.string().required(),
  }),
});

export const BankConfirmationCard = () => {
  const {optimizely, isLoadingParties, isErrorParties} = useParties();
  const { loanDocRequest, hasOpenBankRequest, ldrLoading, ldrError } = createLDR();
  const { bankName, accountNumber, applicationType } = getMerchantProfile();
  const { getPlaidMerchantInfo } = getPlaidInfo();
  const { updateLDR, updateLDRsError, isUpdateLDRsLoading} = updateLDRs();
  const {nextStep, goToStep} = useWizard();
  const { watch } = useFormContext();
  const watchBankConfirmation = watch('bankConfirmation.checkingAccount');

  useEffect(() => {
    (optimizely?.plaidActiveConnectionEnabled === false || applicationType === 'NEW' || hasOpenBankRequest === false) && nextStep();
  }, [optimizely, applicationType, hasOpenBankRequest]);

  if (ldrLoading || isLoadingParties || isUpdateLDRsLoading)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto mt-7"/>
      </Stack>
    );
    if (ldrError || isErrorParties || updateLDRsError) 
      throw new Error(ldrError || isErrorParties || updateLDRsError)

  const handleContinue = async () => {
    if (watchBankConfirmation === 'No') {
      sessionStorage.setItem("bankConfirmation", "No");
      goToStep(LoanStep.BANK_INFO);
    }

    if (watchBankConfirmation === 'Yes') {
      sessionStorage.setItem("bankConfirmation", "Yes");
      await getPlaidMerchantInfo().then(async (res) => {
        const assetsRefreshOcrolusStatus = res?.assetsRefreshOcrolusStatus;
        const isPlaidMerchantInfoEmpty = (res?.plaidMerchantInfo === null && res?.assetsRefreshOcrolusStatus === null);
        if (isPlaidMerchantInfoEmpty === false && assetsRefreshOcrolusStatus === "SUCCESS" && loanDocRequest) {        
          updateLDR([...loanDocRequest.map(col => ({id: col.id, status: 'NOT_REQUIRED'}))]);
          return goToStep(LoanStep.RECEIVED);
        } 
        return goToStep(LoanStep.BANK_INFO); 
      }); 
    }
  }

  return (
    <Stack gap={5}>
      <BlueBlob>
        <FormattedMessageDiv className="h3" id="renewalApp.step.3"/>
      </BlueBlob>
      <Card className="px-lg-5">
        <Stack gap={5} className="p-5">
          <Stack>
            <h4><FormattedMessageDiv id={'loanApp.step.bankConfirmation.heading'} className='text-center'/></h4>
            <FormattedMessageDiv id={'loanApp.step.bankConfirmation.subheading'} className='text-center' values={{
              b: (txt: any) => <b>{txt}</b>
            }}/>
          </Stack>
          <Stack direction='vertical' gap={5} className="align-items-stretch px-lg-4">
            <Col className="d-flex mx-auto w-100">
              <BankDetailsCard bankName={bankName} accountNumber={accountNumber}/>
            </Col>
            <Col className="d-flex mx-auto">
              <FormRadio name="bankConfirmation.checkingAccount" label={null} inline={false} required={false} gap={2}
                         useYupErrors={true}
                         options={['Yes', 'No']}/>
            </Col>
          </Stack>
        </Stack>
      </Card>
      <Row className="justify-content-center text-center gx-5 mx-auto">
        <WizardNav continueOnly={true} continueText={'Continue'} className="px-0 col mb-6 mx-2" style={{width: '255px'}}
                   schema={BankConfirmationSchema} onContinue={handleContinue}/>
      </Row>
    </Stack>
  );
};

export interface LOCCardProps {
  bankName: string;
  accountNumber: string;
}

const BankDetailsCard = ({bankName, accountNumber}: LOCCardProps) => (
  <BsCard bg="light-gray" className='p-5 shadow-none w-100 rounded-2'>
    <Stack direction='vertical' gap={5}>
      <BankIcon className='mx-auto'/>
      <Stack gap={1} className="fs-7">
        <FormattedMessageDiv
          id='loanApp.step.bankConfirmation.bankName'
          values={{bankName: bankName}}
          className='text-center fw-bold'
        />
        <FormattedMessageDiv
          id="loanApp.step.bankConfirmation.bankText"
          values={{lastFour: accountNumber?.slice(-4)}}
          className='text-center'
        />
      </Stack>
    </Stack>
  </BsCard>
)

export default BankConfirmationCard;
