import {useSWRConfig} from 'swr';
import useSWRMutation from "swr/mutation";

export interface RenewalResponse {
  applicationId: string;
  applicationStatus: string;
  decisionStatus: string;
}

export interface PostRenewalProps {
  response: any;
  renewalSending: boolean;
  renewalError: string;
  sendRenewal: any;
}

export function postRenewal(): PostRenewalProps {
  const apiUrl = import.meta.env['VITE_BUTTERFLY_URL']
  const { fetcher } = useSWRConfig();

  async function sendRequest(url, { arg }) {
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify({...arg})});
  };

  const { data, error, trigger, isMutating } = useSWRMutation(`${apiUrl}/v2/orchestration/renew`, sendRequest);
  return {
    response: data,
    renewalSending: isMutating,
    renewalError: error,
    sendRenewal: trigger
  }
}

export default postRenewal;
