import { Stack } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';
import { useWizardFormContext } from './WizardForm';
import * as yup from 'yup';
import React, { useState } from 'react';
import { Button } from '@odmonorepo/core-components';

interface WizardNavProps {
  validate?: boolean;
  submit?: boolean;
  disabled?: boolean;
  schema?: yup.ObjectSchema<any>;
  continueText?: React.ReactNode;
  continueOnly?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onContinue?: () => any | Promise<any>
}

export const WizardNav = ({
  validate = true,
  submit,
  disabled = false,
  continueText,
  continueOnly = false,
  schema,
  className = "mx-auto",
  style = { width: '200px' },
  onContinue = () => true,
}: WizardNavProps) => {
  const [loading, setLoading] = useState(false);
  const { previousStep, nextStep, isFirstStep } = useWizard();
  const { triggerPartial } = useWizardFormContext();
  const handleValidate = async () => {
    if (!validate) return true;
    const valid = await triggerPartial(schema);
    return valid ? Promise.resolve(onContinue()).then(v => v === true) : false;
  };

  const handleClick = async () => {
    setLoading(true);
    const valid = await handleValidate();
    valid && nextStep()
    setLoading(false);
  };

  if (continueOnly) {
    return (
      <Stack>
        <Button
          onClick={handleClick}
          loading={loading}
          className={className}
          style={style}
          disabled={disabled}
        >
          {continueText || 'Continue'}
        </Button>
      </Stack>
    );
  }
  return (
    <Stack direction="horizontal" className="justify-content-center" gap={3}>
      { isFirstStep
        ? <Button variant="outline-primary" style={{ width: '200px' }} href="/">Cancel</Button>
        : <Button variant="outline-primary" style={{ width: '200px' }} onClick={previousStep}>Previous</Button>
      }
      { submit
        ? <Button type="submit" style={{ width: '200px' }} disabled={disabled}>Submit</Button>
        : <Button onClick={handleClick} loading={loading} style={{ width: '200px' }} disabled={disabled}>{continueText || 'Continue'}</Button>
      }
    </Stack>
  );
};

export default WizardNav;
