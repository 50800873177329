export const initApp = async (data) => {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL'];
  const res = await fetch(`${apiUrl}/v1/application/create`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return res
}