import { RenewalEligibility } from 'merchant-api/types';
import useSWR from 'swr';
import useParties from '../useParties/useParties';

export interface RenewalProps {
  isRenewalLoading: boolean;
  renewal: RenewalEligibility;
  isRenewalError: any;
}

export function getRenewal(): RenewalProps {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL']
  const { isRenewal } = useParties();
  const { data, error, isLoading } = useSWR( isRenewal ? `${apiUrl}/v1/renewal` : null);
  return {
    renewal: data,
    isRenewalLoading: isLoading,
    isRenewalError: error
  }
}

export default getRenewal;
