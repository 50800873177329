import {MerchantProfile} from 'merchant-api/types';
import {useApplication} from 'merchant-api/butterfly';
import useSWR from 'swr';

export interface MerchantProfileProps {
  isLoadingMerchantProfile: boolean;
  merchantProfileInfo: MerchantProfile;
  isErrorMerchantProfile: any;
  bankName: string;
  accountNumber: string;
  applicationType: string;
}
export function getMerchantProfile(): MerchantProfileProps {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL']
  const { applicationData } =  useApplication();
  // If the customer is NEW they do not have merchant profile data, so we only call this endpoint for RENEWAL customers
  const { data, error, isLoading } = useSWR(applicationData?.type === 'RENEWAL' ? `${apiUrl}/v2/merchant/profile` : null)
  return {
    isLoadingMerchantProfile: isLoading,
    merchantProfileInfo: data,
    isErrorMerchantProfile: error,
    bankName: data?.merchantBankInfo?.bankName,
    accountNumber: data?.merchantBankInfo?.accountNumber,
    applicationType: applicationData?.type
  }
}

export default getMerchantProfile;
