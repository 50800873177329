import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

export interface PricingResponse {
  lineAmount: number;
  trueCentsOnDollar: number;
  trueAPR: number;
  paymentFrequency: string;
  numberOfPayments: number;
  payment: number;
  disbursementAmount: number;
  totalAmountPaidBack: number;
  lender: string;
  modelId: string;
  loanOriginationFee: number;
  averageMonthlyPayment: number;
  term: number;
  totalLoanCost: number;
  monthlyFee: number;
  ppd?: 100 | 25;
}

export interface PricingRequest {
  applicationType: "RENEWAL" | "NEW";
  customerData: {
    businessCountry: string;
    businessState: string;
    legalEntity: string;
  };
  loanSpecifics: [
    {
      centsOnDollar: number;
      collectionType: "ACH";
      line: number;
      oFeePercentage: number;
      paymentFrequency: string;
      product: string;
      productType: string;
      term: number;
    }
  ];
}

export interface GetPricingProps {
  response: any;
  getPricingSending: boolean;
  getPricingError: string;
  getPricing: (args: PricingRequest) => Promise<PricingResponse>;
}

export function getPricingDetails(): GetPricingProps {
  const apiUrl = import.meta.env['VITE_OFFER_ORCHESTRATOR_URL']
  const {fetcher} = useSWRConfig();

  async function sendRequest(url, {arg}: {arg: PricingRequest}) {
    const data = await fetcher!(url, {method: 'POST', body: JSON.stringify({...arg})}) as PricingResponse;
    return {...data} as PricingResponse;
  };

  const {data, error, trigger, isMutating} = useSWRMutation(`${apiUrl}/v1/pricing`, sendRequest);

  return {
    response: data,
    getPricingSending: isMutating,
    getPricingError: error,
    getPricing: trigger
  }
}

export default getPricingDetails;