import { Stack } from 'react-bootstrap';
import { BusinessContactSchema, BusinessContact } from './Cards/BusinessContact';
import { BusinessDetailsSchema, BusinessDetails } from './Cards/BusinessDetails';
import { BlueBlob, ErrorPage, FormattedMessageDiv, LoadingAnimation, WizardForm, WizardNav, combineSchemas} from '@odmonorepo/core-components';
import { OwnerContactSchema, OwnerContact } from './Cards/OwnerContact';
import { OwnerDetailsSchema, OwnerDetails } from './Cards/OwnerDetails';
import AppReviewCard from './Cards/AppReview';
import DeclinedCard from './Cards/DeclinedCard';
import ReceivedCard from './Cards/ReceivedCard';
import BankConfirmationCard from "./Cards/BankConfirmationCard";
import BankInfoCard from './Cards/BankInfoCard';
import { loanDefaultValues, loanStep } from './helpers';
import { analytics } from 'merchant-components';
import { useEffect } from 'react';
import FinanceNeeds from './Cards/FinanceNeeds';
import ConsentCard from './Cards/ConsentCard';
import { CreateAccountCard } from '../CreateAccount/CreateAccountCard';

const schema = [
  BusinessContactSchema,
  BusinessDetailsSchema,
  OwnerContactSchema,
  OwnerDetailsSchema,
];

export enum LoanStep {
  FINANCE_STEP,
  BUSINESS_STEP,
  OWNER_STEP,
  CONSENT,
  APP_REVIEW,
  BANK_CONFIRMATION,
  BANK_INFO,
  RECEIVED,
  DECLINED,
  PW_SET
}

export const LoanAppForm = () => {
  const { defaultValues, loading } = loanDefaultValues();
  const { step, stepError, stepLoading, applicationId } = loanStep();

  useEffect(() => {
    applicationId && analytics.track('tm_analytics', applicationId, {
      plugins: {
        all: false,
        tm_analytics: true
      }
    })
  }, [applicationId])

  if (stepLoading || loading)
    return (
      <Stack>
        <LoadingAnimation className="mx-auto mt-7" />
      </Stack>
    );

  if (stepError) return <ErrorPage/>;

  return (
    <WizardForm startIndex={step} schema={combineSchemas(schema)} defaultValues={defaultValues}>
      <FinanceNeeds/>
      <BusinessStep/>
      <OwnerStep/>
      <ConsentCard/>
      <AppReviewCard/>
      <BankConfirmationCard/>
      <BankInfoCard/>
      <ReceivedCard/>
      <DeclinedCard/>
      <CreateAccountCard/>
    </WizardForm>
  );
};

const BusinessStep = () => (
  <Stack gap={5}>
    <BlueBlob>
      <FormattedMessageDiv className="h3" id="renewalApp.step.1"/>
    </BlueBlob>
    <BusinessContact/>
    <BusinessDetails/>
    <WizardNav schema={combineSchemas([BusinessContactSchema, BusinessDetailsSchema])}/>
  </Stack>
);

const OwnerStep = () => (
  <Stack gap={5}>
    <BlueBlob>
      <FormattedMessageDiv className="h3" id="renewalApp.step.2"/>
    </BlueBlob>
    <OwnerContact/>
    <OwnerDetails/>
    <WizardNav schema={combineSchemas([OwnerContactSchema, OwnerDetailsSchema])}/>
  </Stack>
);

export default LoanAppForm;
