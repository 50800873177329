import {
  Button,
  Card,
  FormattedMessageDiv,
} from '@odmonorepo/core-components';
import { Stack } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from '@tanstack/react-router';
import { useApplication } from 'merchant-api/butterfly';

export function ReceivedCard() {
  const navigate = useNavigate();
  const { applicationData } = useApplication();
  return (
    <Stack gap={5}>
      <FormattedMessageDiv className="h2 text-center" id="application.softDecline.title" values={{br: () => <br/>}} />
      <Card>
      <div className="d-flex justify-content-center">
        <div>
          <FormattedMessage id="application.softDecline.msg" tagName="p" />
          <FormattedMessage id="loan.advisor.questions" values={{tel: (txt: any) => <a href={`tel:${txt}`} className="fw-bold">{txt}</a>}} />
        </div>
      </div>
      {
        applicationData?.type === 'RENEWAL' && (
          <div className="d-flex justify-content-center mt-4">
            <Button onClick={() => navigate({to: '/'})}>
            <FormattedMessage id="btn.returnToDashboard" />
            </Button>
          </div>
        )
      }
      </Card>
    </Stack>

  );
}

export default ReceivedCard;
