import {
  Card,
  FormattedMessageDiv,
  LoadingAnimation,
} from '@odmonorepo/core-components';
import { useEffect } from 'react';
import { Col, Stack } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useWizard } from 'react-use-wizard';
import { LoanStep } from '../LoanAppForm';
import { postRenewal, useApplication, submitApp, updateAppStatus } from 'merchant-api/butterfly';
import { useParties } from 'merchant-api/spiceroad';

export const AppReviewCard = () => {
  const { sendRenewal, renewalError } = postRenewal();
  const { partyId, optimizely, isErrorParties } = useParties();
  const { applicationData, isErrorApplication, refetchApplicaton } =  useApplication();
  const { getValues } = useFormContext();
  const { goToStep } = useWizard();
  const { submit } = submitApp();
  const { updateStatus, updateError } = updateAppStatus();

  useEffect(() => {
    //this whole block needs to be refactored to only call /submit for app intake
    if (applicationData && applicationData.metadata?.channelCode === 'PARTNER_STRATEGIC'){
      submit({runDecisioning: true}).then((res) => {
        switch (res?.decisionStatus) {
          case 'APPROVED':
            return goToStep(LoanStep.BANK_INFO);
          case 'DECLINED':
            return goToStep(LoanStep.DECLINED);
      }});
    }
    if (partyId && applicationData === null) {
      sendRenewal({ ...getValues(), partyId: partyId }).then(async (res) => {
        switch (res?.decisionStatus) {
          case 'APPROVED':
            const newAppData = await refetchApplicaton();
            await updateStatus({ status: 'PREPROCESSED' });
            if (optimizely?.plaidActiveConnectionEnabled === true && newAppData?.type === 'RENEWAL')
              return goToStep(LoanStep.BANK_CONFIRMATION)
            else
              return goToStep(LoanStep.BANK_INFO);
          case 'DECLINED':
            return goToStep(LoanStep.DECLINED);
        }
      });
    }
  }, [partyId, applicationData]);

  if (renewalError || isErrorParties || isErrorApplication || updateError) 
    throw new Error(renewalError || isErrorParties || isErrorApplication || updateError);

  const intl = useIntl();

  return (
    <Card className="text-center">
      <Stack gap={4}>
        <FormattedMessageDiv className="h4" id="renewalApp.review.title" />
        <LoadingAnimation />
        <Stack>
          <FormattedMessageDiv
            className="fw-bold mt-2"
            id="renewalApp.review.questions"
            values={{ tel: (txt: any) => <a href={`tel:${txt}`}>{txt}</a> }}
          />
          <FormattedMessageDiv id="renewalApp.review.para1" />
        </Stack>
        <Stack gap={4} className="fw-bold fs-5 flex-column flex-sm-row">
          <Col
            onClick={() =>
              window.open(
                `tel:${intl.formatMessage({ id: 'renewalApp.review.phone' })}`,
                '_self'
              )
            }
            role="button"
            className="card shadow-none bg-body p-5 "
          >
            <FormattedMessageDiv id="renewalApp.review.phone" />
          </Col>
          <Col
            onClick={() =>
              window.open(
                `mailto:${intl.formatMessage({
                  id: 'renewalApp.review.email',
                })}`,
                '_self'
              )
            }
            role="button"
            className="card shadow-none bg-body p-5 text-primary"
          >
            <FormattedMessageDiv id="renewalApp.review.emailSupport" />
          </Col>
        </Stack>
      </Stack>
    </Card>
  );
};

export default AppReviewCard;
