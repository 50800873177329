/// <reference types="vite/client" />
import { Parties } from 'merchant-api/types';
import useSWR from 'swr';

export interface UseParties {
  isLoadingParties: boolean;
  partiesData: Parties;
  partyId: string | undefined;
  isErrorParties: any;
  isApplicant: boolean;
  isRenewal: boolean;
  hasLOC: string | undefined;
  hasTL: string | undefined;
  ifOptEnabled: boolean;
  personId: string | undefined;
  businessId: string | undefined;
  isOptSalesAgentEnabled: boolean;
  optimizely: any;
}

export function useParties(): UseParties {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL']
  const { data, error, isLoading } = useSWR(`${apiUrl}/v1/parties`, {errorRetryCount: 0});
  return {
    isApplicant: data?.parties?.some((e: any) => e.type === 'APPLICANT'),
    isRenewal: data?.parties?.some((e: any) => e.type === 'CUSTOMER'),
    partiesData: data,
    partyId: data?.parties?.[0]?.id || undefined,
    personId: data?.parties?.[0]?.people?.[0]?.id || undefined,
    businessId: data?.parties?.[0]?.businesses?.[0]?.id || undefined,
    isLoadingParties: isLoading,
    isErrorParties: error,
    hasLOC: data?.parties?.flatMap((p) => p.loans?.filter((l) => l.loanType === 'LINE_OF_CREDIT').map((l) => l.id))[0] || undefined,
    hasTL: data?.parties?.flatMap((p) => p.loans?.filter((l) => l.loanType === 'FIXED_TERM').map((l) => l.id))[0] || undefined,
    ifOptEnabled: data?.instantFundingEnabled,
    isOptSalesAgentEnabled: data?.salesAgentInfoEnabled,
    optimizely: data?.optimizely,
  }
}

export default useParties;
