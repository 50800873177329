import { initApp } from "merchant-api/spiceroad";
import { useNavigate } from '@tanstack/react-router';
import {
  BlueBlob,
  Button,
  Card,
  FormattedMessageDiv,
  FormInput,
  FormMoneyInput,
  WizardForm, 
} from '@odmonorepo/core-components';
import { Col, Row, Stack } from 'react-bootstrap';
import * as yup from 'yup';
import { useState } from "react";

export const BasicInfoSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email('Invalid email format').required(),
  homePhone: yup.number().required(),
  grossAnnualRevenue: yup.number().required(),
});


interface BasicInfoProps {}

export const BasicInfo: React.FC<BasicInfoProps> = () => {
  const navigate = useNavigate();
  const [initError, setInitError] = useState(false);
  
  if (initError) throw new Error('Error initializing application');

  const handleSubmit = async (e: any) => {
    try {
      const response = await initApp({ ...e, businessName: e.firstName + ' ' + e.lastName });
      if (response?.ok) {
        navigate({ to: '/apply' });
      } else {
        setInitError(true);
      }
    } catch (error) {
      setInitError(true);
    }
  };

  return (
    <WizardForm onSubmit={handleSubmit} formName="BasicInfo" schema={BasicInfoSchema}>
      <Stack gap={5}>
        <BlueBlob>
          <FormattedMessageDiv className="h3" id="basicInfo.title" />
        </BlueBlob>
        <Card>
          <Stack gap={3}>
            <Row xs={1} md={2} className="g-3">
              <Col>
                <FormInput name="firstName" />
              </Col>
              <Col>
                <FormInput name="lastName" />
              </Col>
            </Row>
            <Row xs={1} md={2} className="g-3">
              <Col>
                <FormInput name="email" />
              </Col>
              <Col>
                <FormInput name="homePhone" />
              </Col>
            </Row>
            <FormMoneyInput name="grossAnnualRevenue" />
          </Stack>
        </Card>
        <Button type="submit" form="BasicInfo">Submit</Button>
      </Stack>
    </WizardForm>
  );
}

export default BasicInfo;
