import {useSWRConfig} from 'swr';
import useSWRMutation from "swr/mutation";
import useApplication from '../useApplication/useApplication';


export interface PatchAppDataProps {
  response: any;
  patching: boolean;
  patchError: string;
  patchApp: any;
}

export function patchAppData(): PatchAppDataProps {
  const apiUrl = import.meta.env['VITE_BUTTERFLY_URL']
  const { fetcher } = useSWRConfig();
  const { applicationId } = useApplication();
  
  async function sendRequest(url, { arg }) {
    return fetcher && fetcher(url, {method: 'PATCH', body: JSON.stringify(arg), contentType:{"Content-Type": "application/json-patch+json"}});
  };

  const { data, error, trigger, isMutating } = useSWRMutation(`${apiUrl}/v3/orchestration/${applicationId}/data`, sendRequest);
  return {
    response: data,
    patching: isMutating,
    patchError: error,
    patchApp: trigger
  }
}

export default patchAppData;
