import { useApplication } from "merchant-api/butterfly";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

export interface UpdateLDRsProps {
  updateLDR: Function;
  isUpdateLDRsLoading: boolean;
  updateLDRsError: any;
}

interface UpdateLDRsRequest {
  id: number;
  status: string | null;
}

export function updateLDRs(): UpdateLDRsProps {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL'];
  const { fetcher } = useSWRConfig();

  async function sendUpdateLDRsRequest(url, { arg }: { arg: UpdateLDRsRequest[]}) {
    return fetcher && fetcher(url, {method: 'PATCH', body: JSON.stringify(arg)});
  };

  const {applicationData} = useApplication()
  const { isMutating, error, trigger: updateLDRs } = useSWRMutation(`${apiUrl}/v1/ldr/${applicationData?.metadata.sfOpportunityId}`, sendUpdateLDRsRequest);

  return {
    isUpdateLDRsLoading: isMutating,
    updateLDRsError: error,
    updateLDR: updateLDRs
  };
}

export default updateLDRs;
