import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

export interface UpdatePasswordProps {
  updateResult: any;
  updateError: any;
  triggerUpdate: any;
}

export interface updateReq {
  currentPassword: string;
  newPassword: string;
}

export function updatePassword(): UpdatePasswordProps {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL'];
  const { fetcher } = useSWRConfig();

  async function sendRequest(url, { arg }: { arg: updateReq}) {
    return fetcher && fetcher(url, {method: 'PUT', body: JSON.stringify(arg)});
  };

  const { data, error, trigger } = useSWRMutation(`${apiUrl}/v1/users/password`, sendRequest);

  return {
    updateResult: data,
    updateError: error,
    triggerUpdate: trigger
  };
}

export default updatePassword;
