import { Card, FormattedMessageDiv, FormCheckbox, LoadingAnimation, useWizardFormContext } from '@odmonorepo/core-components';
import { patchAppData, useApplication } from 'merchant-api/butterfly';
import { useEffect } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';
import * as yup from 'yup';
  
export const ConsentSchema = yup.object().shape({
  creditConsent: yup.boolean().oneOf([true]),
  eSignConsent: yup.boolean().oneOf([true]),
  ssnConsent: yup.boolean().oneOf([true])
});

export const ConsentCard = () => {
  const { nextStep } = useWizard();
  const { triggerPartial } = useWizardFormContext();
  const { patchApp, patchError } = patchAppData();
  const { applicationData, isLoadingApplication } = useApplication();

  useEffect(() => {
    (applicationData === null || (applicationData && applicationData?.metadata?.channelCode !== 'PARTNER_STRATEGIC')) && nextStep();
  }, [applicationData]);
  
  if (isLoadingApplication) return <LoadingAnimation/>;
  if (patchError) throw new Error(patchError);
  
  const handleNext = async() => {
    const valid = await triggerPartial(ConsentSchema);
    if (applicationData && applicationData.metadata?.channelCode === 'PARTNER_STRATEGIC') {
      valid && patchApp([
        {
          "op": "replace",
          "path": "/creditConsent",
          "value": true
        },
        {
          "op": "replace",
          "path": "/eSignConsent",
          "value": true
        },
      ]).then((res) => res && nextStep());
    }
    else { valid && nextStep() }
  }
  return (
    <Stack gap={5}>
      <FormattedMessageDiv className="h3 text-center mt-6 mb-3" id="loanApp.step.consent"/>
      <Card className="px-5">
        <Stack gap={5}>
          <FormattedMessageDiv id={'loanApp.step.consent.heading'} className='my-2 h4'/>
          <ConsentChecks />
        </Stack>
      </Card>
      <div className='mx-auto'>
        <Button onClick={handleNext} >
          <FormattedMessageDiv id="loanApp.step.consent.button" />
        </Button>
      </div>
      <SubmitDisclaimer/>
    </Stack>
  );
};


const ConsentChecks = () => (
  <Stack gap={3}>
    <FormCheckbox name="creditConsent" className="fs-8" label={<CreditDisclaimer/>}/>
    <FormCheckbox name="eSignConsent" className="fs-8" label={<ESignDisclamier/>}/>
    <FormCheckbox name="ssnConsent" className="fs-8" label={<SSNDisclamier/>}/>
  </Stack>
);

const CreditDisclaimer = () => 
    <FormattedMessageDiv id={`formCheckbox.creditConsent`} values={{
      terms: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/terms`}>{txt}</a>,
      credit: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/creditagreement`}>{txt}</a>}}/>

const ESignDisclamier = () => (
  <FormattedMessageDiv id={`formCheckbox.eSignConsent`} values={{
    consent: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/esignagreement`}>{txt}</a>}}/>
);

const SSNDisclamier = () => 
  <FormattedMessageDiv 
    id="formCheckbox.ssnConsent"
    values={{
      sup: (txt: any) => <sup>{txt}</sup>
    }}
  />

const SubmitDisclaimer = () => (
  <FormattedMessageDiv
    className="fs-8"
    id="loanApp.disclaimer.submit"
    values={{
      terms: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/terms`}>{txt}</a>,
      private: (txt: any) => <a target="_blank" href={`https://online.ondeck.com/ondeck/creditagreement`}>{txt}</a>
    }}
  />
);

export default ConsentCard;
