import {useSWRConfig} from 'swr';
import useSWRMutation from "swr/mutation";
import useApplication from '../useApplication/useApplication';


export interface SubmitAppProps {
  response: any;
  submitting: boolean;
  submitError: string;
  submit: any;
}

export function submitApp(): SubmitAppProps {
  const apiUrl = import.meta.env['VITE_BUTTERFLY_URL']
  const { fetcher } = useSWRConfig();
  const { applicationId } = useApplication();
  
  async function sendRequest(url, { arg }) {
    return fetcher && fetcher(url, {method: 'POST', body: JSON.stringify({...arg})});
  };

  const { data, error, trigger, isMutating } = useSWRMutation(`${apiUrl}/v3/orchestration/${applicationId}/submit`, sendRequest);
  return {
    response: data,
    submitting: isMutating,
    submitError: error,
    submit: trigger
  }
}

export default submitApp;
