import { useIntl, FormattedMessage } from 'react-intl';
import { useState, useEffect } from 'react';
import { Row, Col, Stack, Badge } from 'react-bootstrap';
import {
  Tooltip,
  FormattedMessageDiv,
  FormattedNumberDiv,
  Button,
} from '@odmonorepo/core-components';
import { useWizard } from 'react-use-wizard';
import { combineClassNames } from '@odmonorepo/odfunctions';
import { useNavigate } from '@tanstack/react-router';
import { disablePreview, OfferConfigStep, pricedDetails, remainingBalance } from '../../OfferConfig';
import Decimal from 'decimal.js';
import { selectOffer, useDetailedOffers } from 'merchant-api/offer';
import { useAtom, useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';

export function OfferPreview() {
  const intl = useIntl();
  const { watch } = useFormContext();
  const { goToStep, isLastStep } = useWizard();
  const navigate = useNavigate();
  const { offers, previousLoan } = useDetailedOffers();
  const remaining = useAtomValue(remainingBalance)
  const disabled = useAtomValue(disablePreview)
  const [pricedOffer, setPriced] = useAtom(pricedDetails);
  const offer = watch("offer");
  const { select, selecting} = selectOffer(offer?.offerId);
  
  if (!pricedOffer) return null;

  const selectedOffer = offers?.filter(o => o.offerId === offer.offerId)[0];
  const simpleView = true;

  const media = window.matchMedia('(max-width: 992px)');
  const [vert, setVert] = useState(media.matches);

  useEffect(() => {
    media.addEventListener('change', function () {
      setVert(media.matches);
    });
    return () =>
      media.removeEventListener('change', function () {
        setVert(media.matches);
      });
  }, [media]);

  const handleContinue = () => {
    if (isLastStep || Object.keys(selectedOffer.prePaymentDiscountRelatedOfferIds).length === 0) 
      return select(pricedOffer)
        .then(() => setPriced(undefined))
        .then(() => navigate({to: '/checkout'})
        .then(()=> window.scrollTo({ top: 0, behavior: 'smooth' })));
    goToStep(OfferConfigStep.PPD_OPTION);
  }

  return (
    <Stack
      className={combineClassNames("border shadow-none border-secondary important px-6 py-5", vert ? "rounded-bottom-3" : "rounded-end-3 h-100")}
    >
      <FormattedMessageDiv
        className="fw-bold text-center"
        id="offerPreview.header"
      />
      {!simpleView && (
        <Row className="py-4">
          <Col xs className="text-center">
            <FormattedMessageDiv
              className="fw-bold fs-7"
              id="offerPreview.term"
            />
            <FormattedNumberDiv
              className="h5 mb-0"
              value={pricedOffer.term}
              suffix={` ${intl.formatMessage({ id: 'offerPreview.months' })}`}
            />
          </Col>
          <Col xs className="text-center">
            <FormattedMessageDiv
              className="fw-bold fs-7"
              id="offerPreview.payment"
            />
            <FormattedNumberDiv
              className="h5 mb-0"
              style="currency"
              currency="USD"
              value={pricedOffer.payment}
            />
          </Col>
        </Row>
      )}
      <div className="py-4">
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="offerPreview.capital" />
            <Tooltip
              color="dark"
              title={`${intl.formatMessage({ id: 'offerPreview.capital.tooltip' })}`}
            />
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            style="currency"
            currency="USD"
            value={pricedOffer.totalLoanCost}
          />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="offerPreview.apr" />
            <Tooltip
              color="dark"
              title={`${intl.formatMessage({ id: 'offerPreview.apr.tooltip' })}`}
            />
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            value={pricedOffer.trueAPR}
            suffix={`%`}
            maximumFractionDigits={4}
          />
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 mb-2 pe-2"
            id="offerPreview.cod"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            value={new Decimal(pricedOffer.trueCentsOnDollar).mul(100).sub(100).toNumber()}
            suffix="&cent;"
            maximumFractionDigits={4}
          />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id="offerPreview.prepaymentDiscount" />
            { pricedOffer?.ppd === 25 ? (
              <Tooltip
                color="dark"
                title={`${intl.formatMessage({ id: 'prepayment.tooltip.ppd.25' })}`}
              />
            ) : null}
            { pricedOffer?.ppd === 100 ? (
              <Tooltip
                color="dark"
                title={`${intl.formatMessage({ id: 'prepayment.tooltip.ppd.100' })}`}
              />
            ) : null}
          </div>
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            value={pricedOffer?.ppd as number}
            suffix={`%`}
            maximumFractionDigits={4}
          />
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 mb-2 pe-2"
            id="offerPreview.repaymentAmount"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto mb-2"
            style="currency"
            currency="USD"
            value={pricedOffer.totalAmountPaidBack}
          />
        </Stack>
      </div>
      <Stack gap={2} className="bg-light-gray p-4 ">
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 pe-2"
            id="offerPreview.loanAmount"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto"
            style="currency"
            currency="USD"
            value={pricedOffer.lineAmount}
          />
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 pe-2"
            id="offerPreview.principalBalance"
          />
          <FormattedNumberDiv
            className="fw-bold fs-7 ms-auto"
            style="currency"
            currency="USD"
            value={-remaining}
          />
        </Stack>
        <Stack direction="horizontal">
          <div  className="fs-7 pe-2">
            <FormattedMessage
              id="offerPreview.originationFee"
            />
            <Tooltip
                  color="dark"
                  title={`${intl.formatMessage({ id: 'tooltip.originationFee' })}`}
            />
          </div>
          {pricedOffer.loanOriginationFee ? (
            <FormattedNumberDiv
              className="fw-bold fs-7 ms-auto"
              style="currency"
              currency="USD"
              value={pricedOffer.loanOriginationFee}
            />
          ) : (
            <Badge pill bg="success" className="ms-auto">
              {intl.formatMessage({ id: 'offerPreview.waived' })}
            </Badge>
          )}
        </Stack>
        <Stack direction="horizontal">
          <FormattedMessageDiv
            className="fs-7 pe-2"
            id="offerPreview.prevInterest"
          />
          <Badge pill bg="success" className="ms-auto">
            {intl.formatMessage({ id: 'offerPreview.waived' })}
          </Badge>
        </Stack>
        <Stack direction="horizontal">
          <hr className="w-100 border border-bottom-0 border-disabled" />
        </Stack>
        <Stack direction="horizontal">
          <div className="fs-7 mb-2 pe-2">
            <FormattedMessage id={`${previousLoan ? 'offerPreview.disbursement' : 'offerPreview.disbursementNew'}`} />
            <Tooltip
              color="dark"
              title={`${intl.formatMessage({ id: 'offerPreview.disbursement.tooltip' })}`}
            />
          </div>
          <FormattedNumberDiv
            className="fw-bold ms-auto mb-2"
            style="currency"
            currency="USD"
            value={pricedOffer.lineAmount - remaining - pricedOffer.loanOriginationFee}
          />
        </Stack>
      </Stack>
      <div className="pt-6">
        <Button
          className="w-100"
          disabled={disabled}
          loading={selecting}
          onClick={() => handleContinue()}
        >
          <FormattedMessageDiv id="common.continue" />
        </Button>
      </div>
    </Stack>
  );
}

export default OfferPreview;
