/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as MerchantRouteImport } from './routes/_merchant/route'
import { Route as MerchantTermsRouteImport } from './routes/_merchant/terms/route'
import { Route as MerchantOfferRouteImport } from './routes/_merchant/offer/route'
import { Route as MerchantESignAgreementRouteImport } from './routes/_merchant/eSignAgreement/route'
import { Route as MerchantCreditAgreementRouteImport } from './routes/_merchant/creditAgreement/route'
import { Route as MerchantCheckoutRouteImport } from './routes/_merchant/checkout/route'
import { Route as MerchantApplyRouteImport } from './routes/_merchant/apply/route'
import { Route as MerchantPortalRouteImport } from './routes/_merchant/_portal/route'
import { Route as publicApplyRouteImport } from './routes/(public)/apply/route'
import { Route as MerchantTermsIndexImport } from './routes/_merchant/terms/index'
import { Route as MerchantOfferIndexImport } from './routes/_merchant/offer/index'
import { Route as MerchantESignAgreementIndexImport } from './routes/_merchant/eSignAgreement/index'
import { Route as MerchantCreditAgreementIndexImport } from './routes/_merchant/creditAgreement/index'
import { Route as MerchantCheckoutIndexImport } from './routes/_merchant/checkout/index'
import { Route as MerchantApplyIndexImport } from './routes/_merchant/apply/index'
import { Route as MerchantPortalIndexImport } from './routes/_merchant/_portal/index'
import { Route as MerchantPortalRenewalImport } from './routes/_merchant/_portal/renewal'
import { Route as MerchantPortalProfileImport } from './routes/_merchant/_portal/profile'
import { Route as MerchantPortalPaymentImport } from './routes/_merchant/_portal/payment'
import { Route as MerchantPortalInstantfundingImport } from './routes/_merchant/_portal/instantfunding'
import { Route as MerchantPortalDrawfundsImport } from './routes/_merchant/_portal/drawfunds'
import { Route as MerchantPortalDocumentsImport } from './routes/_merchant/_portal/documents'
import { Route as MerchantPortalBusinessImport } from './routes/_merchant/_portal/business'
import { Route as publicApplyLandingImport } from './routes/(public)/apply/landing'

// Create/Update Routes

const MerchantRouteRoute = MerchantRouteImport.update({
  id: '/_merchant',
  getParentRoute: () => rootRoute,
} as any)

const MerchantTermsRouteRoute = MerchantTermsRouteImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const MerchantOfferRouteRoute = MerchantOfferRouteImport.update({
  id: '/offer',
  path: '/offer',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const MerchantESignAgreementRouteRoute =
  MerchantESignAgreementRouteImport.update({
    id: '/eSignAgreement',
    path: '/eSignAgreement',
    getParentRoute: () => MerchantRouteRoute,
  } as any)

const MerchantCreditAgreementRouteRoute =
  MerchantCreditAgreementRouteImport.update({
    id: '/creditAgreement',
    path: '/creditAgreement',
    getParentRoute: () => MerchantRouteRoute,
  } as any)

const MerchantCheckoutRouteRoute = MerchantCheckoutRouteImport.update({
  id: '/checkout',
  path: '/checkout',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const MerchantApplyRouteRoute = MerchantApplyRouteImport.update({
  id: '/apply',
  path: '/apply',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const MerchantPortalRouteRoute = MerchantPortalRouteImport.update({
  id: '/_portal',
  getParentRoute: () => MerchantRouteRoute,
} as any)

const publicApplyRouteRoute = publicApplyRouteImport.update({
  id: '/(public)/apply',
  path: '/apply',
  getParentRoute: () => rootRoute,
} as any)

const MerchantTermsIndexRoute = MerchantTermsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantTermsRouteRoute,
} as any)

const MerchantOfferIndexRoute = MerchantOfferIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantOfferRouteRoute,
} as any)

const MerchantESignAgreementIndexRoute =
  MerchantESignAgreementIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => MerchantESignAgreementRouteRoute,
  } as any)

const MerchantCreditAgreementIndexRoute =
  MerchantCreditAgreementIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => MerchantCreditAgreementRouteRoute,
  } as any)

const MerchantCheckoutIndexRoute = MerchantCheckoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantCheckoutRouteRoute,
} as any)

const MerchantApplyIndexRoute = MerchantApplyIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantApplyRouteRoute,
} as any)

const MerchantPortalIndexRoute = MerchantPortalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalRenewalRoute = MerchantPortalRenewalImport.update({
  id: '/renewal',
  path: '/renewal',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalProfileRoute = MerchantPortalProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalPaymentRoute = MerchantPortalPaymentImport.update({
  id: '/payment',
  path: '/payment',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalInstantfundingRoute =
  MerchantPortalInstantfundingImport.update({
    id: '/instantfunding',
    path: '/instantfunding',
    getParentRoute: () => MerchantPortalRouteRoute,
  } as any)

const MerchantPortalDrawfundsRoute = MerchantPortalDrawfundsImport.update({
  id: '/drawfunds',
  path: '/drawfunds',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalDocumentsRoute = MerchantPortalDocumentsImport.update({
  id: '/documents',
  path: '/documents',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const MerchantPortalBusinessRoute = MerchantPortalBusinessImport.update({
  id: '/business',
  path: '/business',
  getParentRoute: () => MerchantPortalRouteRoute,
} as any)

const publicApplyLandingRoute = publicApplyLandingImport.update({
  id: '/landing',
  path: '/landing',
  getParentRoute: () => publicApplyRouteRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_merchant': {
      id: '/_merchant'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MerchantRouteImport
      parentRoute: typeof rootRoute
    }
    '/(public)/apply': {
      id: '/(public)/apply'
      path: '/apply'
      fullPath: '/apply'
      preLoaderRoute: typeof publicApplyRouteImport
      parentRoute: typeof rootRoute
    }
    '/_merchant/_portal': {
      id: '/_merchant/_portal'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MerchantPortalRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/apply': {
      id: '/_merchant/apply'
      path: '/apply'
      fullPath: '/apply'
      preLoaderRoute: typeof MerchantApplyRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/checkout': {
      id: '/_merchant/checkout'
      path: '/checkout'
      fullPath: '/checkout'
      preLoaderRoute: typeof MerchantCheckoutRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/creditAgreement': {
      id: '/_merchant/creditAgreement'
      path: '/creditAgreement'
      fullPath: '/creditAgreement'
      preLoaderRoute: typeof MerchantCreditAgreementRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/eSignAgreement': {
      id: '/_merchant/eSignAgreement'
      path: '/eSignAgreement'
      fullPath: '/eSignAgreement'
      preLoaderRoute: typeof MerchantESignAgreementRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/offer': {
      id: '/_merchant/offer'
      path: '/offer'
      fullPath: '/offer'
      preLoaderRoute: typeof MerchantOfferRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/_merchant/terms': {
      id: '/_merchant/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof MerchantTermsRouteImport
      parentRoute: typeof MerchantRouteImport
    }
    '/(public)/apply/landing': {
      id: '/(public)/apply/landing'
      path: '/landing'
      fullPath: '/apply/landing'
      preLoaderRoute: typeof publicApplyLandingImport
      parentRoute: typeof publicApplyRouteImport
    }
    '/_merchant/_portal/business': {
      id: '/_merchant/_portal/business'
      path: '/business'
      fullPath: '/business'
      preLoaderRoute: typeof MerchantPortalBusinessImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/documents': {
      id: '/_merchant/_portal/documents'
      path: '/documents'
      fullPath: '/documents'
      preLoaderRoute: typeof MerchantPortalDocumentsImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/drawfunds': {
      id: '/_merchant/_portal/drawfunds'
      path: '/drawfunds'
      fullPath: '/drawfunds'
      preLoaderRoute: typeof MerchantPortalDrawfundsImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/instantfunding': {
      id: '/_merchant/_portal/instantfunding'
      path: '/instantfunding'
      fullPath: '/instantfunding'
      preLoaderRoute: typeof MerchantPortalInstantfundingImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/payment': {
      id: '/_merchant/_portal/payment'
      path: '/payment'
      fullPath: '/payment'
      preLoaderRoute: typeof MerchantPortalPaymentImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/profile': {
      id: '/_merchant/_portal/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof MerchantPortalProfileImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/renewal': {
      id: '/_merchant/_portal/renewal'
      path: '/renewal'
      fullPath: '/renewal'
      preLoaderRoute: typeof MerchantPortalRenewalImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/_portal/': {
      id: '/_merchant/_portal/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof MerchantPortalIndexImport
      parentRoute: typeof MerchantPortalRouteImport
    }
    '/_merchant/apply/': {
      id: '/_merchant/apply/'
      path: '/'
      fullPath: '/apply/'
      preLoaderRoute: typeof MerchantApplyIndexImport
      parentRoute: typeof MerchantApplyRouteImport
    }
    '/_merchant/checkout/': {
      id: '/_merchant/checkout/'
      path: '/'
      fullPath: '/checkout/'
      preLoaderRoute: typeof MerchantCheckoutIndexImport
      parentRoute: typeof MerchantCheckoutRouteImport
    }
    '/_merchant/creditAgreement/': {
      id: '/_merchant/creditAgreement/'
      path: '/'
      fullPath: '/creditAgreement/'
      preLoaderRoute: typeof MerchantCreditAgreementIndexImport
      parentRoute: typeof MerchantCreditAgreementRouteImport
    }
    '/_merchant/eSignAgreement/': {
      id: '/_merchant/eSignAgreement/'
      path: '/'
      fullPath: '/eSignAgreement/'
      preLoaderRoute: typeof MerchantESignAgreementIndexImport
      parentRoute: typeof MerchantESignAgreementRouteImport
    }
    '/_merchant/offer/': {
      id: '/_merchant/offer/'
      path: '/'
      fullPath: '/offer/'
      preLoaderRoute: typeof MerchantOfferIndexImport
      parentRoute: typeof MerchantOfferRouteImport
    }
    '/_merchant/terms/': {
      id: '/_merchant/terms/'
      path: '/'
      fullPath: '/terms/'
      preLoaderRoute: typeof MerchantTermsIndexImport
      parentRoute: typeof MerchantTermsRouteImport
    }
  }
}

// Create and export the route tree

interface MerchantPortalRouteRouteChildren {
  MerchantPortalBusinessRoute: typeof MerchantPortalBusinessRoute
  MerchantPortalDocumentsRoute: typeof MerchantPortalDocumentsRoute
  MerchantPortalDrawfundsRoute: typeof MerchantPortalDrawfundsRoute
  MerchantPortalInstantfundingRoute: typeof MerchantPortalInstantfundingRoute
  MerchantPortalPaymentRoute: typeof MerchantPortalPaymentRoute
  MerchantPortalProfileRoute: typeof MerchantPortalProfileRoute
  MerchantPortalRenewalRoute: typeof MerchantPortalRenewalRoute
  MerchantPortalIndexRoute: typeof MerchantPortalIndexRoute
}

const MerchantPortalRouteRouteChildren: MerchantPortalRouteRouteChildren = {
  MerchantPortalBusinessRoute: MerchantPortalBusinessRoute,
  MerchantPortalDocumentsRoute: MerchantPortalDocumentsRoute,
  MerchantPortalDrawfundsRoute: MerchantPortalDrawfundsRoute,
  MerchantPortalInstantfundingRoute: MerchantPortalInstantfundingRoute,
  MerchantPortalPaymentRoute: MerchantPortalPaymentRoute,
  MerchantPortalProfileRoute: MerchantPortalProfileRoute,
  MerchantPortalRenewalRoute: MerchantPortalRenewalRoute,
  MerchantPortalIndexRoute: MerchantPortalIndexRoute,
}

const MerchantPortalRouteRouteWithChildren =
  MerchantPortalRouteRoute._addFileChildren(MerchantPortalRouteRouteChildren)

interface MerchantApplyRouteRouteChildren {
  MerchantApplyIndexRoute: typeof MerchantApplyIndexRoute
}

const MerchantApplyRouteRouteChildren: MerchantApplyRouteRouteChildren = {
  MerchantApplyIndexRoute: MerchantApplyIndexRoute,
}

const MerchantApplyRouteRouteWithChildren =
  MerchantApplyRouteRoute._addFileChildren(MerchantApplyRouteRouteChildren)

interface MerchantCheckoutRouteRouteChildren {
  MerchantCheckoutIndexRoute: typeof MerchantCheckoutIndexRoute
}

const MerchantCheckoutRouteRouteChildren: MerchantCheckoutRouteRouteChildren = {
  MerchantCheckoutIndexRoute: MerchantCheckoutIndexRoute,
}

const MerchantCheckoutRouteRouteWithChildren =
  MerchantCheckoutRouteRoute._addFileChildren(
    MerchantCheckoutRouteRouteChildren,
  )

interface MerchantCreditAgreementRouteRouteChildren {
  MerchantCreditAgreementIndexRoute: typeof MerchantCreditAgreementIndexRoute
}

const MerchantCreditAgreementRouteRouteChildren: MerchantCreditAgreementRouteRouteChildren =
  {
    MerchantCreditAgreementIndexRoute: MerchantCreditAgreementIndexRoute,
  }

const MerchantCreditAgreementRouteRouteWithChildren =
  MerchantCreditAgreementRouteRoute._addFileChildren(
    MerchantCreditAgreementRouteRouteChildren,
  )

interface MerchantESignAgreementRouteRouteChildren {
  MerchantESignAgreementIndexRoute: typeof MerchantESignAgreementIndexRoute
}

const MerchantESignAgreementRouteRouteChildren: MerchantESignAgreementRouteRouteChildren =
  {
    MerchantESignAgreementIndexRoute: MerchantESignAgreementIndexRoute,
  }

const MerchantESignAgreementRouteRouteWithChildren =
  MerchantESignAgreementRouteRoute._addFileChildren(
    MerchantESignAgreementRouteRouteChildren,
  )

interface MerchantOfferRouteRouteChildren {
  MerchantOfferIndexRoute: typeof MerchantOfferIndexRoute
}

const MerchantOfferRouteRouteChildren: MerchantOfferRouteRouteChildren = {
  MerchantOfferIndexRoute: MerchantOfferIndexRoute,
}

const MerchantOfferRouteRouteWithChildren =
  MerchantOfferRouteRoute._addFileChildren(MerchantOfferRouteRouteChildren)

interface MerchantTermsRouteRouteChildren {
  MerchantTermsIndexRoute: typeof MerchantTermsIndexRoute
}

const MerchantTermsRouteRouteChildren: MerchantTermsRouteRouteChildren = {
  MerchantTermsIndexRoute: MerchantTermsIndexRoute,
}

const MerchantTermsRouteRouteWithChildren =
  MerchantTermsRouteRoute._addFileChildren(MerchantTermsRouteRouteChildren)

interface MerchantRouteRouteChildren {
  MerchantPortalRouteRoute: typeof MerchantPortalRouteRouteWithChildren
  MerchantApplyRouteRoute: typeof MerchantApplyRouteRouteWithChildren
  MerchantCheckoutRouteRoute: typeof MerchantCheckoutRouteRouteWithChildren
  MerchantCreditAgreementRouteRoute: typeof MerchantCreditAgreementRouteRouteWithChildren
  MerchantESignAgreementRouteRoute: typeof MerchantESignAgreementRouteRouteWithChildren
  MerchantOfferRouteRoute: typeof MerchantOfferRouteRouteWithChildren
  MerchantTermsRouteRoute: typeof MerchantTermsRouteRouteWithChildren
}

const MerchantRouteRouteChildren: MerchantRouteRouteChildren = {
  MerchantPortalRouteRoute: MerchantPortalRouteRouteWithChildren,
  MerchantApplyRouteRoute: MerchantApplyRouteRouteWithChildren,
  MerchantCheckoutRouteRoute: MerchantCheckoutRouteRouteWithChildren,
  MerchantCreditAgreementRouteRoute:
    MerchantCreditAgreementRouteRouteWithChildren,
  MerchantESignAgreementRouteRoute:
    MerchantESignAgreementRouteRouteWithChildren,
  MerchantOfferRouteRoute: MerchantOfferRouteRouteWithChildren,
  MerchantTermsRouteRoute: MerchantTermsRouteRouteWithChildren,
}

const MerchantRouteRouteWithChildren = MerchantRouteRoute._addFileChildren(
  MerchantRouteRouteChildren,
)

interface publicApplyRouteRouteChildren {
  publicApplyLandingRoute: typeof publicApplyLandingRoute
}

const publicApplyRouteRouteChildren: publicApplyRouteRouteChildren = {
  publicApplyLandingRoute: publicApplyLandingRoute,
}

const publicApplyRouteRouteWithChildren =
  publicApplyRouteRoute._addFileChildren(publicApplyRouteRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof MerchantPortalRouteRouteWithChildren
  '/apply': typeof MerchantApplyRouteRouteWithChildren
  '/checkout': typeof MerchantCheckoutRouteRouteWithChildren
  '/creditAgreement': typeof MerchantCreditAgreementRouteRouteWithChildren
  '/eSignAgreement': typeof MerchantESignAgreementRouteRouteWithChildren
  '/offer': typeof MerchantOfferRouteRouteWithChildren
  '/terms': typeof MerchantTermsRouteRouteWithChildren
  '/apply/landing': typeof publicApplyLandingRoute
  '/business': typeof MerchantPortalBusinessRoute
  '/documents': typeof MerchantPortalDocumentsRoute
  '/drawfunds': typeof MerchantPortalDrawfundsRoute
  '/instantfunding': typeof MerchantPortalInstantfundingRoute
  '/payment': typeof MerchantPortalPaymentRoute
  '/profile': typeof MerchantPortalProfileRoute
  '/renewal': typeof MerchantPortalRenewalRoute
  '/': typeof MerchantPortalIndexRoute
  '/apply/': typeof MerchantApplyIndexRoute
  '/checkout/': typeof MerchantCheckoutIndexRoute
  '/creditAgreement/': typeof MerchantCreditAgreementIndexRoute
  '/eSignAgreement/': typeof MerchantESignAgreementIndexRoute
  '/offer/': typeof MerchantOfferIndexRoute
  '/terms/': typeof MerchantTermsIndexRoute
}

export interface FileRoutesByTo {
  '': typeof MerchantRouteRouteWithChildren
  '/apply': typeof MerchantApplyIndexRoute
  '/apply/landing': typeof publicApplyLandingRoute
  '/business': typeof MerchantPortalBusinessRoute
  '/documents': typeof MerchantPortalDocumentsRoute
  '/drawfunds': typeof MerchantPortalDrawfundsRoute
  '/instantfunding': typeof MerchantPortalInstantfundingRoute
  '/payment': typeof MerchantPortalPaymentRoute
  '/profile': typeof MerchantPortalProfileRoute
  '/renewal': typeof MerchantPortalRenewalRoute
  '/': typeof MerchantPortalIndexRoute
  '/checkout': typeof MerchantCheckoutIndexRoute
  '/creditAgreement': typeof MerchantCreditAgreementIndexRoute
  '/eSignAgreement': typeof MerchantESignAgreementIndexRoute
  '/offer': typeof MerchantOfferIndexRoute
  '/terms': typeof MerchantTermsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_merchant': typeof MerchantRouteRouteWithChildren
  '/(public)/apply': typeof publicApplyRouteRouteWithChildren
  '/_merchant/_portal': typeof MerchantPortalRouteRouteWithChildren
  '/_merchant/apply': typeof MerchantApplyRouteRouteWithChildren
  '/_merchant/checkout': typeof MerchantCheckoutRouteRouteWithChildren
  '/_merchant/creditAgreement': typeof MerchantCreditAgreementRouteRouteWithChildren
  '/_merchant/eSignAgreement': typeof MerchantESignAgreementRouteRouteWithChildren
  '/_merchant/offer': typeof MerchantOfferRouteRouteWithChildren
  '/_merchant/terms': typeof MerchantTermsRouteRouteWithChildren
  '/(public)/apply/landing': typeof publicApplyLandingRoute
  '/_merchant/_portal/business': typeof MerchantPortalBusinessRoute
  '/_merchant/_portal/documents': typeof MerchantPortalDocumentsRoute
  '/_merchant/_portal/drawfunds': typeof MerchantPortalDrawfundsRoute
  '/_merchant/_portal/instantfunding': typeof MerchantPortalInstantfundingRoute
  '/_merchant/_portal/payment': typeof MerchantPortalPaymentRoute
  '/_merchant/_portal/profile': typeof MerchantPortalProfileRoute
  '/_merchant/_portal/renewal': typeof MerchantPortalRenewalRoute
  '/_merchant/_portal/': typeof MerchantPortalIndexRoute
  '/_merchant/apply/': typeof MerchantApplyIndexRoute
  '/_merchant/checkout/': typeof MerchantCheckoutIndexRoute
  '/_merchant/creditAgreement/': typeof MerchantCreditAgreementIndexRoute
  '/_merchant/eSignAgreement/': typeof MerchantESignAgreementIndexRoute
  '/_merchant/offer/': typeof MerchantOfferIndexRoute
  '/_merchant/terms/': typeof MerchantTermsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/apply'
    | '/checkout'
    | '/creditAgreement'
    | '/eSignAgreement'
    | '/offer'
    | '/terms'
    | '/apply/landing'
    | '/business'
    | '/documents'
    | '/drawfunds'
    | '/instantfunding'
    | '/payment'
    | '/profile'
    | '/renewal'
    | '/'
    | '/apply/'
    | '/checkout/'
    | '/creditAgreement/'
    | '/eSignAgreement/'
    | '/offer/'
    | '/terms/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/apply'
    | '/apply/landing'
    | '/business'
    | '/documents'
    | '/drawfunds'
    | '/instantfunding'
    | '/payment'
    | '/profile'
    | '/renewal'
    | '/'
    | '/checkout'
    | '/creditAgreement'
    | '/eSignAgreement'
    | '/offer'
    | '/terms'
  id:
    | '__root__'
    | '/_merchant'
    | '/(public)/apply'
    | '/_merchant/_portal'
    | '/_merchant/apply'
    | '/_merchant/checkout'
    | '/_merchant/creditAgreement'
    | '/_merchant/eSignAgreement'
    | '/_merchant/offer'
    | '/_merchant/terms'
    | '/(public)/apply/landing'
    | '/_merchant/_portal/business'
    | '/_merchant/_portal/documents'
    | '/_merchant/_portal/drawfunds'
    | '/_merchant/_portal/instantfunding'
    | '/_merchant/_portal/payment'
    | '/_merchant/_portal/profile'
    | '/_merchant/_portal/renewal'
    | '/_merchant/_portal/'
    | '/_merchant/apply/'
    | '/_merchant/checkout/'
    | '/_merchant/creditAgreement/'
    | '/_merchant/eSignAgreement/'
    | '/_merchant/offer/'
    | '/_merchant/terms/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  MerchantRouteRoute: typeof MerchantRouteRouteWithChildren
  publicApplyRouteRoute: typeof publicApplyRouteRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  MerchantRouteRoute: MerchantRouteRouteWithChildren,
  publicApplyRouteRoute: publicApplyRouteRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_merchant",
        "/(public)/apply"
      ]
    },
    "/_merchant": {
      "filePath": "_merchant/route.tsx",
      "children": [
        "/_merchant/_portal",
        "/_merchant/apply",
        "/_merchant/checkout",
        "/_merchant/creditAgreement",
        "/_merchant/eSignAgreement",
        "/_merchant/offer",
        "/_merchant/terms"
      ]
    },
    "/(public)/apply": {
      "filePath": "(public)/apply/route.tsx",
      "children": [
        "/(public)/apply/landing"
      ]
    },
    "/_merchant/_portal": {
      "filePath": "_merchant/_portal/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/_portal/business",
        "/_merchant/_portal/documents",
        "/_merchant/_portal/drawfunds",
        "/_merchant/_portal/instantfunding",
        "/_merchant/_portal/payment",
        "/_merchant/_portal/profile",
        "/_merchant/_portal/renewal",
        "/_merchant/_portal/"
      ]
    },
    "/_merchant/apply": {
      "filePath": "_merchant/apply/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/apply/"
      ]
    },
    "/_merchant/checkout": {
      "filePath": "_merchant/checkout/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/checkout/"
      ]
    },
    "/_merchant/creditAgreement": {
      "filePath": "_merchant/creditAgreement/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/creditAgreement/"
      ]
    },
    "/_merchant/eSignAgreement": {
      "filePath": "_merchant/eSignAgreement/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/eSignAgreement/"
      ]
    },
    "/_merchant/offer": {
      "filePath": "_merchant/offer/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/offer/"
      ]
    },
    "/_merchant/terms": {
      "filePath": "_merchant/terms/route.tsx",
      "parent": "/_merchant",
      "children": [
        "/_merchant/terms/"
      ]
    },
    "/(public)/apply/landing": {
      "filePath": "(public)/apply/landing.tsx",
      "parent": "/(public)/apply"
    },
    "/_merchant/_portal/business": {
      "filePath": "_merchant/_portal/business.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/documents": {
      "filePath": "_merchant/_portal/documents.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/drawfunds": {
      "filePath": "_merchant/_portal/drawfunds.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/instantfunding": {
      "filePath": "_merchant/_portal/instantfunding.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/payment": {
      "filePath": "_merchant/_portal/payment.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/profile": {
      "filePath": "_merchant/_portal/profile.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/renewal": {
      "filePath": "_merchant/_portal/renewal.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/_portal/": {
      "filePath": "_merchant/_portal/index.tsx",
      "parent": "/_merchant/_portal"
    },
    "/_merchant/apply/": {
      "filePath": "_merchant/apply/index.tsx",
      "parent": "/_merchant/apply"
    },
    "/_merchant/checkout/": {
      "filePath": "_merchant/checkout/index.tsx",
      "parent": "/_merchant/checkout"
    },
    "/_merchant/creditAgreement/": {
      "filePath": "_merchant/creditAgreement/index.tsx",
      "parent": "/_merchant/creditAgreement"
    },
    "/_merchant/eSignAgreement/": {
      "filePath": "_merchant/eSignAgreement/index.tsx",
      "parent": "/_merchant/eSignAgreement"
    },
    "/_merchant/offer/": {
      "filePath": "_merchant/offer/index.tsx",
      "parent": "/_merchant/offer"
    },
    "/_merchant/terms/": {
      "filePath": "_merchant/terms/index.tsx",
      "parent": "/_merchant/terms"
    }
  }
}
ROUTE_MANIFEST_END */
