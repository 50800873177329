import { Button, Card, FormattedMessageDiv } from '@odmonorepo/core-components';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from '@tanstack/react-router';
import { useApplication } from 'merchant-api/butterfly';
import { Stack } from 'react-bootstrap';

export function DeclinedCard() {
  const navigate = useNavigate();
  const { applicationData } = useApplication();
  
  return (
    <Stack gap={5}>
      <div className="d-flex flex-column align-items-center mb-5">
        <FormattedMessageDiv
        className="h2 text-center"
        id="application.declined.title"
        />
      </div>
      <Card className="p-4">
        <div>
          <FormattedMessage id="application.declined.msg" tagName="p" />
          <FormattedMessage
            id="loan.advisor.questions"
            values={{
              tel: (txt: any) => {
                return (
                  <a href={`tel:${txt}`} className="fw-bold">
                    {txt}
                  </a>
                );
              },
            }}
          />
        </div>
        {
          applicationData?.type === 'RENEWAL' && (
            <div className="d-flex justify-content-center mt-4">
              <Button onClick={() => navigate({to: '/'})}>
              <FormattedMessage id="btn.returnToDashboard" />
              </Button>
            </div>
          )
        }
      </Card>
    </Stack>
  );
}

export default DeclinedCard;
