export const merchantPortalText = {
  'application.declined.title': 'Unfortunately, your application was declined.',
  'application.declined.msg':
    'We were unable to approve your application for funding. You will receive a Notice of Adverse Action within the next 30 days.',
  'application.softDecline.title': 'We received your application.<br></br>Thank you!',
  'application.softDecline.msg':
    "We'll contact you shortly (typically within 1 hour on business days) to discuss next steps.",
  'bankData.alert.mismatch': "This doesn’t match the bank information on your application. Please try again.",
  'bankData.alert.somethingWrong': 'Something went wrong. Please try again.',
  'bankData.checkAltText': 'Check example',
  'bankData.instructions': 'This account will be used for ACH funding and automatic loan payments. Your funds will be sent within 2 - 3 business days. Check out before 10:30 a.m. EST to get same-day funding at no extra cost.†',
  'bankData.LOC.disclaimer': "Once you confirm and open your line of credit, we’ll use your bank account information to debit your monthly fee. When you borrow, we’ll deposit the money and begin debiting payments pursuant to the terms of your line of credit agreement.",
  'bankData.subtitle': 'Provide your primary business checking account.',
  'bankData.title': 'Where should the funds go?',
  'bankData.TL.disclaimer': "The Lender, as identified in your loan agreement, will transfer the funds to this account once you’ve read and agreed to the terms on the next page. Payments will be automatically debited from your account pursuant to the terms of your Business Loan and Security Agreement. Thus, your fixed daily payment will be direct-debited from your account Monday through Friday one business day after the disbursement of your loan funds is initiated. Your fixed weekly payment will be automatically debited from your account the same day each week as the day of the week disbursement of your loan funds is initiated (or the next business day, if the payment is due on a non-business day). Your fixed monthly payment will be automatically debited from your account on the 26th day of the month as provided for in your Business Loan and Security Agreement. For example, if disbursement of the loan funds is initiated on the 2nd day of the month, then your payment will be debited on the 26th day of the same month the disbursement is initiated (unless the 26th day of the month is not a business day, in which case the debit would occur the following business day). If the disbursement is initiated after the 19th day of the month, then the payment will be automatically debited on the 26th day of the following month (or the next business day, if the 26th day of the month does not fall on a business day).",
  'basicInfo.title': 'Basic Information',
  'borrowing.details.header': 'Borrowing Details',
  'borrowing.details.APR': 'APR',
  'borrowing.details.CoD': 'Cents on the Dollar (CoD)',
  'borrowing.details.monthly.interest': 'Monthly Interest Rate',
  'borrowing.details.tooltip': 'This is the estimated amount of interest that you will pay per dollar borrowed on each draw, assuming you will make a single draw of your full credit limit and repay the draw pursuant to the terms of your Business Line of Credit Agreement without taking additional draws.',
  'business.inputLabel.businessAddress': 'Business Address',
  'business.inputLabel.bankAcc': 'Bank Account',
  'business.inputLabel.entityType': 'Entity Type',
  'business.inputLabel.legalName': 'Legal Name',
  'business.inputLabel.phone': 'Phone',
  'business.inputLabel.taxId': 'Tax ID',
  'btn.downloadCSV': 'Download Complete History',
  'btn.filter': 'Filter',
  'btn.loadMore': 'Load More',
  'btn.returnToDashboard': 'Return to Dashboard',
  'btn.viewAll': 'View All',
  'checkout.requiredFirstDraw.afterCheckout': 'After checkout, your funds will be deposited into your bank account within 2 - 3 business days.',
  'checkout.requiredFirstDraw.chooseOrEnter': 'Choose or enter an amount.',
  'checkout.requiredFirstDraw.minimumRequired': 'The minimum required draw is {amount}.',
  'checkout.requiredFirstDraw.repaymentEstimateMonthly': '<b>12 monthly</b> estimated payments of <b>{amount}</b> based on the selected draw.',
  'checkout.requiredFirstDraw.repaymentEstimateWeekly': '<b>52 weekly</b> estimated payments of <b>{amount}</b> based on the selected draw.',
  'checkout.requiredFirstDraw.repaymentSchedule': 'Repayment Schedule',
  'checkout.requiredFirstDraw.selectDrawAmount': 'Select your first draw amount.',
  'checkoutSuccess.loc.notice1': 'Your checkout is complete.',
  'checkoutSuccess.loc.notice2': 'It may take a few minutes for the update to appear in your account.',
  'checkoutSuccess.success': 'Success',
  'checkoutSuccess.tl.notice1': "Your funds are on the way! They'll be deposited in your bank account in 2 to 3 business days, or as soon as 5 p.m. today with Same Day Funding.",
  'checkoutSuccess.tl.notice2': '<b>Please allow a few minutes for us to update your account to reflect your new funding</b> — you may need to refresh the home page.',
  'checkoutSuccess.tl.tooltip': 'Same Day Funding is only available in certain states, for term loans up to $100K. Eligibility window is Monday-Friday before 10:30am EST. If checkout is done before 10:30am EST, funds will be available by 5pm local time the same day. If checkout is done after 10:30am EST, or on a weekend or bank holiday, the loan will not qualify for Same Day Funding and funds will be deposited within 2-3 business days.',

  // NOTE: Whenever updating any text in this block, be sure to also update the date in CreditAgreement.tsx
  'creditAgreement.updated': 'Updated and effective { date }', // Will be formatted like "April 22, 2021"
  'creditAgreement.1.header': 'Credit and Background Check Authorization',
  'creditAgreement.1.body': 'This authorization applies to OnDeck Capital, Inc., ODK Capital, LLC, and any of its affiliates, subsidiaries, agents doing business under the On Deck name, and assignees (collectively “OnDeck”, “Us”, “Our”, or We”). You understand and agree that OnDeck and our agents and assignees are authorized to contact third parties to conduct background checks and other investigative reports, including but not limited to your business credit card sales data as described below, and make credit report inquiries (including requesting personal credit bureau reports about you and any other owner(s) of the business who will be providing a personal guarantee, and business credit bureaus about your company, in either case, from credit reporting agencies and other sources) or for any other lawful purpose. OnDeck has such authority for the foregoing in connection with any extension of credit to the company on whose behalf you are applying, conducting loan file updates, ongoing loan reviews, renewal of financing, or referral of your business to third party lenders. Upon your written request, we will advise you if we obtained a credit report. You understand and agree that OnDeck is making no commitment or guarantee that any product, evaluation, or guidance provided by OnDeck will result in an approval of credit from OnDeck or any OnDeck Banking Partner.',
  'creditAgreement.2.header': 'Credit Card Data Collection',
  'creditAgreement.2.body.1': 'You understand and agree that We and Our agents and assignees are authorized to contact third party credit card processors and networks used by your company in order to obtain historical and ongoing information/metrics related to:',
  'creditAgreement.2.body.2': 'Your company, including, but not limited to, company name, address, phone number, and industry; and',
  'creditAgreement.2.body.3': 'Your company’s credit card sales, including, but not limited to, transaction spend, number of transactions, and repeat customers.',
  'creditAgreement.2.body.4': 'Such information and metrics will be used by OnDeck for purposes of providing the OnDeck Service, including, but are not limited to, sales agent call routing, underwriting, customer management, and enhancement of OnDeck’s credit model.',

  'common.contactNumber': '18889983483',
  'common.numberFormat': '# (###) ###-####',
  'common.privacyPolicy': 'Privacy Policy',
  'common.back': 'Back',
  'common.cancel': 'Cancel',
  'common.edit': 'Edit',
  'common.home': 'Home',
  'common.new': 'New',
  'common.required': 'Required',
  'common.incorrectFormat': 'Incorrect Format',
  'common.save': 'Save',
  'common.securityPolicy': 'Security Policy',
  'common.customerServiceEmail': 'customerservice@ondeck.com',
  'contactInfo.businessHours': 'M-F 9am-8pm ET',
  'contactInfo.phone.helpline': '1 (888) 998-3483',
  'contactInfo.phone.helpline.link': 'tel:1-888-998-3483',
  'contactOndeck.contactCS.message': 'Contact Customer Service',
  'contactOndeck.hereToHelp.message': 'We are here to help',
  'contactOndeck.hoursAvailable.message': 'M - F, 9 a.m. - 8 p.m. ET',
  'customErrorMessage.title': "We found an issue with your account.",
  'customErrorMessage.body':'Contact your loan advisor, or call customer service at <tel>(888) 556-3483</tel> to resolve this issue and access your funds.',
  'documents.loadMore.action': 'Load more',
  'documents.monthlyStatements.title': 'Statements',
  'documents.otherDocuments.title': 'Loan Documents',
  'documents.requestDocuments':
    'To request additional documents, please call our Customer Service team at <tel>1 (888) 998-3483</tel>, M-F 9am-8pm ET',
  'documents.title': 'Documents',
  'documentCenter.title': 'Documents Center',
  'drawFunds.title': 'Draw Funds',
  'drawFunds.availableCredit': 'Available to Draw',
  'drawFunds.loanId': 'Loan ID #',
  'drawFunds.selectDrawAmount': 'Select Draw Amount',
  'drawFunds.chooseAmount': 'Choose Amount',
  'drawFunds.reviewPricing': 'Review Your Pricing',
  'drawFunds.drawAmount': 'Draw Amount',
  'drawFunds.weeklyAutoPayment': 'New Weekly Auto-Payment',
  'drawFunds.monthlyAutoPayment': 'New Monthly Auto-Payment',
  'drawFunds.repaymentPeriod': 'Repayment Period',
  'drawFunds.outstandingBalance': 'New Outstanding Balance',
  'drawFunds.outstandingBalance.tooltip.msg':
    'Early payoff can help reduce APR, which may result in a lower total repayment amount. To see your APR, refer to your Line of Credit Agreement.',
  'drawFunds.reviewFundingMethod': 'Review Funding Method',
  'drawFunds.instantFunding': 'Instant Funding',
  'drawFunds.ach': 'ACH',
  'drawFunds.instantFunding.description':
    'Receive money immediately. Funds will be in your account in minutes, even if banks are closed. Available at no extra cost.',
  'drawFunds.instantFunding.unableToDraw':
    'Unavailable for draws over $10,000.',
  'drawFunds.instantFunding.drawOverLimit':
    'Instant Funding is unavailable for draws over $10,000.',
  'drawFunds.instantFunding.drawMoreThanMax':
    'Unable to draw more than max value.',
  'drawFunds.instantFunding.drawLessThanMin':
    'Unable to draw less than min value',
  'drawFunds.ach.description':
    'Receive money in 1 - 2 business days. If the draw is made before 10:30 a.m. ET, funds will be in your account the same day.',
  'drawFunds.button.cancel': 'Cancel',
  'drawFunds.button.confirmDraw': 'Confirm Draw',
  'drawFunds.instantFunding.withdrawals.info':
    'Instant Funding withdrawals are currently supported from your desktop and the OnDeck mobile website, not in the OnDeck mobile app. Occasionally, transfer may take up to 30 minutes to complete due to potential Visa & bank processing lags. This benefit is available at no additional cost with a vast majority of major banks participating. Instant Funding is available for transactions between $1K-$10K.',
  'drawFunds.ach.withdrawals.info':
    'For ACH withdrawals, if draw is initiated Monday-Friday after 10:30am EST, or on a weekend or bank holiday, it will not qualify for Same Day Funding and funds will be deposited within 1-2 business days. Same Day Funding is only available in certain states, for draws up to $100K. Eligibility window is Monday-Friday before 10:30am EST. If draw is initiated before 10:30am EST, funds will be available by 5pm local time the same day.',
  'drawFunds.withdrawals.tc':
    'Please ensure all information is correct. By confirming, you authorize this draw and understand that OnDeck will process this transaction. Note, limit one draw per day.',
  'drawFunds.loc.helpline':
    'It is 100% our fault. Please try refreshing the page, and if that does not work, please email us at CustomerService@ondeck.com or give us a call at <tel>(828) 7475449</tel>. We appreciate your patience and apologize for the inconvenience.',
  'drawFunds.loc.drawOnHold':
    'Draw has been put on hold for this loan. Please email us at CustomerService@ondeck.com or give us a call at <tel>(888) 556-3483</tel> for assistance.',
  'drawFunds.loc.success': 'Transaction successful. Your bank may take 4-24 hours to process this transaction.',
  'drawFunds.lessThanThousand.title': 'You can’t draw funds right now.',
  'drawFunds.lessThanThousand.message': 'You must have at least $1,000 in available credit to draw funds. Make a payment to increase your available credit. Please note that it may take up to 5 business days for the payment to reflect in your available credit.',
  'createAccount.title': 'Create New Password',
  'createAccount.error': 'There is an error creating your account. Please try again.',
  'createAccount.success': 'Success! Your account has been created.',
  'errorPage.title': "We're having some technical trouble.",
  'errorPage.button.returnHome': 'Return home',
  'errorPage.content.paragraph.1':
    "Please try refreshing the page, and if that doesn't work, please ",
  'errorPage.content.paragraph.2': 'send us an email ',
  'errorPage.content.paragraph.3': 'or give us a call at ',
  'errorPage.content.paragraph.4':
    '. We appreciate your patience and apologize for the inconvenience.',

  // NOTE: Whenever updating any text in this block, be sure to also update the date in Terms.tsx
  'eSignAgreement.updated': 'Updated and effective { date }', // Will be formatted like "April 22, 2021"
  'eSignAgreement.header': 'E-Sign Consent Agreement',
  'eSignAgreement.body.1': 'By clicking the check box next to the E-Sign Consent Agreement, you are accepting all of the following terms and conditions relating to the electronic notification/communications policy of On Deck Capital, Inc., ODK Capital, LLC, and any affiliates doing business under the On Deck name (collectively "OnDeck") and OnDeck’s electronic consent and contract binding policy. Please read the information below carefully and thoroughly.',
  'eSignAgreement.body.2': 'YOU AGREE TO ELECTRONIC CONTRACT BINDING. You consent that your electronic signature on agreements and documents has the same legal and moral effect as if you signed such agreements and documents in ink and will be deemed valid, authentic, enforceable and binding. You understand and acknowledge that the federal Electronic Signatures in Global and National Commerce Act defines an “electronic signature” as an electronic sound, symbol or process, attached to or logically associated with a contract or other record and executed or adopted by a person with the intent to be bound by such contract or record. Based on this definition, you further consent that your electronic actions or your participation in certain electronic processes that are logically associated with a contract or any fully disclosed terms and conditions shall also have the same legal effect as if you signed such contract or agreed to such terms and conditions by providing your written signature in ink and you agree that such actions/participation will be deemed a valid and binding contract to the extent such actions/participation indicates your intent to be legally bound.',
  'eSignAgreement.body.3': 'YOU AGREE TO ELECTRONIC COMMUNICATIONS. You hereby agree that OnDeck may provide any communication or notification to you electronically and you hereby consent to receive by electronic means any and all agreements, documents, disclosures and/or notices required to be given by applicable law, regulation or internal OnDeck policy. You also consent to allow OnDeck to respond to any inquiries or communications by e-mail, fax or other electronic means regardless of the format of the original inquiry. You agree that electronic copies of communications are valid and you will not contest the validity or enforceability of such communications or any related transactions, absent proof of altered data or tampering. Pursuant to law and regulations, you agree and acknowledge that all electronic communications delivered to you by OnDeck (i) shall be given the same legal effect as signed paper communications, (ii) shall be considered a “writing” or “in writing” and (iii) shall be deemed for all purposes to have been “signed” and to constitute an “original” when printed from electronic files or records established and maintained in the normal course of business. You agree that all electronic communications and actions recorded by OnDeck shall be deemed valid and admissible originals.',
  'eSignAgreement.body.4': 'YOU UNDERSTAND THE TECHNICAL REQUIREMENTS. To electronically receive, view, and save electronic communications and electronically signed agreements, you must have a personal computer equipped with the requirements set forth below, and either a printer, a hard drive, or other storage device. You must also have a valid email address. You must notify us if your email address changes and you hereby agree to notify us of any such change immediately. To notify us of your new email address, please log on to ondeck.com and the applicable subdomains and update your email address in the “My Profile” section of the website. Please note that the minimum requirements set forth below are subject to change.',
  'eSignAgreement.body.5': 'YOU UNDERSTAND THE DISCLOSURES ABOUT CHANGING YOUR CONSENT. If you elect to receive required notices and disclosures only in paper format, it may slow the speed at which we can complete certain steps in transactions with you and delivering services to you. Nonetheless, you may change your consent at any time. Your consent to electronic communications and E-signing may also be withdrawn at any time by providing us with written notice that has been notarized and sent to the address below. Notwithstanding your change of consent, any electronic communications provided or agreements entered into with your electronic signature prior to your consent being withdrawn shall remain effective and binding.',
  'eSignAgreement.body.6.1': 'OnDeck',
  'eSignAgreement.body.6.2': 'E-Sign Department',
  'eSignAgreement.body.6.3': '901 N Stuart St, Suite 700',
  'eSignAgreement.body.6.4': 'Arlington, VA 22203',
  'eSignAgreement.body.7': 'Your correspondence must contain in the body of such request your e-mail address, full name, US Postal address, and telephone number. After your withdrawal of consent has been received and processed by OnDeck, all subsequent notifications and communications will be sent by regular mail to the last known address on file with OnDeck. You have the option to receive any information that we have provided electronically in paper form at no cost to you by providing a written request to the address above. Additionally, you will have the ability to download and print certain documents through the OnDeck Online system.',
  'eSignAgreement.body.8': 'YOU ACKNOWLEDGE AND CONFIRM YOUR CONSENT. If you consent to receiving notices and disclosures in electronic format on the terms and conditions described above, please let us know by clicking the check box next to “E-Sign Consent Agreement” on the previous page. By clicking the check box, you acknowledge and confirm that (1) you can access and read this E-SIGN CONSENT AGREEMENT; (2) you can print on paper the disclosure or save or send the disclosure to a place where you can print it, for future reference and access; and (3) until or unless you notify OnDeck as described above, you consent to receive from and through electronic means all notices, disclosures, authorizations, acknowledgements, and other documents that are required to be provided or made available to you by OnDeck during the course of your relationship with OnDeck.',
  'eSignAgreement.requiredHardware.header': 'Required hardware and software',
  'eSignAgreement.requiredHardware.1.key': 'Operating Systems:',
  'eSignAgreement.requiredHardware.1.value': 'Windows® 2000, Windows® XP, Windows Vista®; Mac OS® X',
  'eSignAgreement.requiredHardware.2.key': 'Browsers:',
  'eSignAgreement.requiredHardware.2.value': 'Final release versions of Internet Explorer® 6.0 or above (Windows only); Mozilla Firefox 2.0 or above (Windows and Mac); Safari™ 3.0 or above (Mac only)',
  'eSignAgreement.requiredHardware.3.key': 'PDF Reader:',
  'eSignAgreement.requiredHardware.3.value': 'Acrobat® or similar software may be required to view and print PDF files',
  'eSignAgreement.requiredHardware.4.key': 'Screen Resolution:',
  'eSignAgreement.requiredHardware.4.value': '800 x 600 minimum',
  'eSignAgreement.requiredHardware.5.key': 'Enabled Security Settings:',
  'eSignAgreement.requiredHardware.5.value': 'Allow per session cookies',

  'financeNeeds.step.1': 'Step 1: Finance Needs',
  'forgotPassword.button': 'Submit',
  'forgotPassword.buttonAfterSubmitted': 'Back to Sign In',
  'forgotPassword.buttonAfterSubmittedAuthenticated': 'Back to Profile',
  'forgotPassword.connection.error': 'Connection error. Please try again later.',
  'forgotPassword.heading': 'Password Reset',
  'forgotPassword.label':
    "Enter the email address you used to apply with OnDeck and we'll send you instructions to reset your password",
  'forgotPassword.submittedCopy':
    "We just sent you an updated password reset link.  If you don't see it in your inbox, remember to check your spam folder.",
  'genericError.heading': "We're having some technical trouble.",
  'genericError.body':
    "Please try refreshing the page, and if that doesn't work, please",
  'genericError.body2':
    'We appreciate your patience and apologize for the inconvenience.',
  'genericError.email.message': '<email>send us an email</email>',
  'genericError.email.mailto': 'customerservice@ondeck.com',
  'genericError.phone':
    'or give us a call at <tel>1 (888) 269-4246</tel> Option 3',
  'legal.copyrightFormality':
    'OnDeck® is a registered trademark. All rights reserved.',
  'legal.loanDisclaimer.state':
    'Depending on the state where your business is located and other attributes of your business and the loan, your business loan may be issued by a member of the OnDeck family of companies or by Celtic Bank, a Utah-Chartered Industrial Bank, Member FDIC. Your loan agreement will identify the lender prior to your signing. Loans subject to lender approval.',
  'legal.loansDisclaimer': 'Loans subject to lender approval.',
  'loading.error': 'Looks like we had an issue loading.',
  'loading.error.activity': 'Looks like we had an issue loading your Activity.',
  'loading.error.documents':
    'Looks like we had an issue loading your Documents.',
  'loading.error.profile': 'Looks like we had an issue loading your Profile.',
  'loan.advisor': 'Want to speak to a Loan Advisor?',
  'loan.advisor.helpline':
    'Call <tel>(888) 727-5449</tel> to schedule a free consultation in order to receive insights and guidance.',
  'loan.advisor.questions':
    'If you have any questions, please call customer service at <tel>(888) 556-3483</tel>.',
  'loanDetails.loanId.display': 'Loan ID #',
  'loanDetails.payoffAmount.display': 'Payoff today: {payoffAmount}',
  'loanDetails.payoffAmount.additionalPayments':
    'To make a payment or request a payoff, please call our Customer Service team at <tel>1 (888) 998-3483</tel>',
  'loanDetails.payoffDate.display': 'Approximate payoff date: {payoffDate}',
  'loanDetails.termLoan.display': '${num} Term Loan',
  'loanDetails.currentBalance.message': 'Current Balance',
  'loanDetails.totalPaid.message': 'Total Paid',
  'loanDetails.customerService.message':
    'To update this information, please call our Customer Service team at <tel>1 (888) 998-3483</tel>, M-F 9am-8pm ET',
  'loanDetails.currentBalance.tooltip.message':
    'This is the total amount due including principal, fees and interest.',
  'loc.display': '${num} Line of Credit',
  'loc.loanId.display': 'Loan ID #{id}',
  'loc.currentBalance.message': 'Current Balance',
  'loc.currentBalance.tooltip.message':
    ' This is the total amount due including fee and interest',
  'loc.availableCredit.message': 'Available Credit',
  'loc.drawFunds': 'Draw Funds',
  'loc.instantFundingEligibleHeader': 'You’re eligible for Instant Funding!',
  'loc.instantFundingEligiblePara':
    'Receive funds in minutes every time you make a draw — at no extra cost.',
  'loc.instantFundingEligibleSignUpBtn': 'Sign Up',
  'loc.iFrame.message':
    'Please provide your <b>business</b> debit card information to continue.',
  'loc.iFrame.list1':
    'Card must be associated with the bank account OnDeck has on file.',
  'loc.iFrame.list2': 'Use the exact name on your card.',
  'login.error.accessDenied.message':
    'Your email or password is incorrect. Please try again.',
  'login.error.tooManyAttempts.message':
    "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it.",
  'navigation.link.contactUs': 'Contact Us',
  'navigation.link.dashboard': 'Dashboard',
  'navigation.link.documents': 'Documents',
  'navigation.link.drawFunds': 'Draw Funds',
  'navigation.link.makePayment': 'Make a Payment',
  'navigation.link.signOut': 'Sign Out',
  'navigation.link.editProfile': 'Edit Profile',
  'notFoundPage.title': "Unfortunately, we can't find that page",
  'notFoundPage.button.returnHome': 'Return home',
  'notFoundPage.content.paragraph.1':
    'Please try going back. If you are still stuck, give us a call at ',
  'notFoundPage.content.paragraph.2':
    ". We're standing by Monday-Friday from 9:00AM - 6:00PM EST.",
  'notification.alert.title': "There was an issue with your business tax ID.",
  'notification.alert.body': "Your loan advisor can help. Call {agentName} at {agentPhoneNumber} to resolve this issue and access your funds.",
  'notification.message.declined':
    'We were unable to approve your recent application for additional funding. You will receive a Notice of Adverse Action within 30 days of our decision.',
  'notification.message.renewal':
    'You may be eligible for a term loan renewal or line of credit.',
  'notification.message.started': 'Please complete your application.',
  'notification.message.submitted':
    "We'll let you know when we've made a decision.",
  'notification.renewal': 'Apply Now',
  'notification.title.declined':
    'Unfortunately, your application was declined.',
  'notification.title.renewal': 'Need more funding?',
  'notification.title.started': 'Additional information needed.',
  'notification.title.submitted': "We're reviewing your application.",
  'notification.title.openOffer':
    "Good news! You've been approved for a loan up to ",
  'notification.title.dualOffer':
    'Good news! You have multiple funding options.',
  'notification.title.locOffer':
    'Good news! You’ve been approved for a line of credit up to ',
  'notification.callToAction.openOffer': 'Access Funds',
  'notification.callToAction.csNumber': 'Call (888) 556-3483',
  'notification.csNumber.helpline': 'tel:1(888) 556-3483',
  'notification.dismissMessage.openOffer': 'Not Now',
  'notification.started': 'Continue',
  'offRamp.returnHome': 'Return Home',
  'offRamp.text': "We've taken note of the edits that you have made to your profile. Hang tight while we process this new information and we will get back to you as soon as we can.",
  'offRamp.title': 'Thanks for submitting your information.',
  'OTP.both.heading': 'Make a Payment',
  'OTP.both.remaining': 'Your remaining balance is {amount}.',
  'OTP.LOC.remaining':
    'To make a full payment on your current outstanding balance, please do so between 10 a.m. - 5:30 p.m. ET on a business day (Monday - Friday, excluding bank holidays) to avoid accruing additional interest.*',
  'OTP.both.loanId': 'Loan ID #{id}',
  'OTP.both.payment': 'Payment Amount',
  'OTP.both.custom': 'Custom Amount',
  'OTP.both.nextBusinessDay': 'Next Business Day',
  'OTP.LOC.customInfo':
    'Payment will be reflected in your available credit within 5 business days.',
  'OTP.both.date': 'Payment Date',
  'OTP.both.from': 'Pay From',
  'OTP.LOC.info':
    'This one-time payment will be in addition to your scheduled payments. We will continue to debit {amount} on each scheduled payment date. Your next payment date is {date}.',
  'OTP.TL.info':
    'If you have an outstanding balance after this payment, your next auto-payment of {amount} will be scheduled for {date}.',
  'OTP.LOC.disclaimer':
    '<sup>*</sup>This is an additional payment and will apply to both your principal and interest balances. Please note that any payment made after 5:30 p.m. ET on a business day or at any time on a non-business day will not be credited to the account until the following business day and will accrue additional interest. Any additional interest accrued will be automatically deducted from your designated account on your next auto-payment date.',
  'OTP.TL.disclaimer':
    'This is an additional payment on your loan payment schedule and will apply to both your principal and interest balances. We will continue to debit your account according to your loan agreement. Please note that any payment made after 5:30 ET on a business day or any time on a non-business day will not be credited to the account until the following business day.',
  'OTP.both.checkText':
    'Please ensure that all information is correct. By confirming, you authorize payment on or after the Payment Date and understand that OnDeck will process this transaction.',
  'OTP.both.cancel': 'Cancel',
  'OTP.both.error': 'Connection error. Please try again later.',
  'OTP.both.error.header': 'Payment failed. Please try again.',
  'OTP.both.error.text':
    'If the problem persists, contact our customer support team at (888) 556-3483.',
  'OTP.both.return': 'Return Home',
  'OTP.both.submit': 'Submit Payment',
  'OTP.overPaymentAlert.TL':
    'This amount is close to your outstanding balance. To move forward, contact us at <tel> (888) 556-3483 </tel> -  you could be eligible for an early repayment discount.',
  'OTP.overpayment.LOC':
    'Please enter an amount lower than your outstanding balance.',
  'OTP.overpayment.TL': 'Please enter a lower payment amount.',
  'profile.inputLabel.email': 'Email',
  'profile.inputLabel.homePhone': 'Home Phone',
  'profile.inputLabel.alternatePhone': 'Alternate Phone',
  'profile.inputLabel.city': 'City',
  'profile.inputLabel.street': 'Street',
  'profile.inputLabel.unit': 'Unit',
  'profile.inputLabel.zip': 'Zip Code',
  'plaid.button': 'Find My Bank',
  'plaid.title.M3.false': 'Connect With Plaid',
  'plaid.title.M3.true': 'Option 1: Connect With Plaid',
  'plaid.description':
    'We’ll need to review the most recent 90 days of activity on your business checking account. Please provide this information using our secure account connector, Plaid.<sup>*</sup> They partner with 10,000+ financial institutions to quickly connect bank accounts to businesses. <link>Learn more about Plaid</link>.',
  'plaid.disclamier':
    "<sup>*</sup>By connecting your account through Plaid, you are authorizing Plaid to access and transmit your account information to ODK Capital, LLC on an ongoing basis. We will use your information for a number of purposes, including to evaluate any application for funds, to conduct periodic reviews to evaluate your credit limit or determine whether you might benefit from a payment plan, to determine your eligibility for other products, services or features and to improve, modify or develop our products and services. Plaid’s access and use of data may differ. See <privacy>Plaid's Privacy Policy</privacy> for more information. To disconnect your account from Plaid, go to <link>my.plaid.com</link>.",
  'plaid.icon.text1':
    'Your personal data and financial information are encrypted.',
  'plaid.icon.text2':
    'We will not have access to withdraw from or deposit to your account.',
  'plaid.customer.service':
    'If you can’t find your bank with Plaid, please call Customer Service at <tel>(888) 556-3483</tel>.',
  'resetPassword.error.unsuccessful.login':
    'We are having trouble updating your password. Please check if the email or password that you provided is valid.',
  'resetPassword.error.weak.password': 'Password does not match requirement.',
  'resetPassword.input.email': 'Email',
  'resetPassword.input.currentPassword': 'Current Password',
  'resetPassword.input.newPassword': 'New Password',
  'resetPassword.password.requirement':
    'Must be between 8-30 characters and include at least one number (0-9), at least one special character, and both upper and lowercase letters.',
  'resetPassword.success': 'Your password has been reset successfully.',
  'title.activity': 'Transaction History',
  'title.documents': 'Documents',
  'title.notification': 'Notification',
  'title.loc': 'Line of Credit',
  'title.termloan': 'Term Loan',
  'title.drawFund': 'Draw Fund',
  'title.product': 'Product',
  'title.business': 'Business',
  'title.profile': 'Profile',
  'title.sideNav': 'Side Nav',
  'title.fundingCard': 'Funding Card',
  'transactionHistory.downloadCSV.action': 'Download CSV',
  'transactionHistory.heading': 'Activity',
  'transactionHistory.loadMore.action': 'Load More',
  'upcomingPayments.heading': 'Next Payment',
  'upcomingPayments.autoPay.enrolled': 'Enrolled in {frequency} Auto Pay',
  'upcomingPayments.autoPay.notEnrolled': 'Not enrolled in Auto Pay',
  'upcomingPayments.info.additionalPayments':
    'To make additional payments or request a payoff, please call our Customer Service team at <tel>1 (888) 998-3483</tel>, M-F 9am-8pm ET',
  'upcomingPayments.info.pastDue':
    'Your account is past due; to make additional payments, please call our Payment Support team at <tel>1 (888) 994-6603</tel>, M-F 9am-8pm ET',
  'upcomingPayments.info.onHold': 'Account on hold',
  'upcomingPayments.makePayment': 'Make a Payment',
  'odoRenewal.loginError':
    "We're experiencing technical difficulties. Please try again later.",
  'instantFunding.title': 'Instant Funding',
  'instantFunding.drawFunds.btn': 'Draw Funds',
  'instantFunding.returnToDashBoard.btn': 'Return to Dashboard',
  'instantFunding.success.header': 'Success! You can now use Instant Funding.',
  'instantFunding.success.para':
    'Draw funds anytime and receive them in minutes.',
  'instantFunding.card.ending.visa': 'Visa debit card ending in ',
  'instantFunding.card.ending.mastercard': 'MasterCard debit card ending in ',
  'instantFunding.registered.label': 'Registered',
  'instantFunding.registered.toolTip.msg':
    'If you need to update your debit card, please call <tel>(888) 556-3483</tel>.',
  'instantFunding.tsAndCs.subtitle': 'Access funds the moment you need them.',
  'instantFunding.tsAndCs.getFunds':
    'Get funds in minutes when you make a draw between $1,000 - $10,000. Available nights, weekends and holidays.',
  'instantFunding.tsAndCs.fundsAccess': '24/7 access to funds',
  'instantFunding.tsAndCs.immediate': 'Immediate electronic transfer',
  'instantFunding.tsAndCs.cost': 'Available at no extra cost',
  'instantFunding.tsAndCs.heading': 'Instant Funding Terms & Conditions',
  'instantFunding.tsAndCs.disclosure1':
    'I hereby agree to the above terms and conditions.',
  'instantFunding.tsAndCs.disclosure2':
    "Once registered by completing and submitting the form on the next page, I permit OnDeck to fund my business' OnDeck Line of Credit draw through Instant Funding as described above. By clicking agree, I will be sent to the registration page.",
  'instantFunding.tsAndCs.para1':
    'Instant Funding is only available with participating banks, requires registration, and is subject to these terms and conditions. Occasionally, a transfer may take up to 30 minutes to complete, and fund availability is subject to your debit card and bank processing times. Instant Funding supports transactions between $1,000 - $10,000, but your bank may have their own limits on the size of transactions that can occur. You may only make one draw request per day.',
  'instantFunding.tsAndCs.para2':
    'Only the debit card associated with the business bank account that OnDeck has on file to fund your Line of Credit draws can be used for Instant Funding, and the information associated with your debit card must match the information registered with your OnDeck account. Draws of less than $1,000 or more than $10,000, or where the ACH option is selected, are ineligible for Instant Funding. When you register, the debit card information will be accessed and processed by a third party that works with the debit card networks – OnDeck will not have access to nor will OnDeck retain any debit card information.',
  'instantFunding.tsAndCs.para3':
    'In addition to all the foregoing, you understand and agree that Instant Funding will be used only for OnDeck Line of Credit draws for business purposes; that the information provided on the next page is the true debit card information associated with the business bank account on file with OnDeck; OnDeck neither controls nor is responsible for the performance of the debit card networks, your company’s bank or their respective vendors; and you have authority by your business to register for Instant Funding. If you have any issues registering for Instant Funding, or need to make a change to your selections, please call OnDeck customer service at (888) 556-3483. Instant Funding may be affected by service disruptions, and transfers could be declined, delayed, or blocked if we identify an issue. We reserve the right to change or update Instant Funding and these terms at any time for any reason.',
  'instantFunding.tsAndCs.continue': 'Continue',
  'instantFunding.tsAndCs.cancel': 'Cancel',
  'instantFunding.tsAndCs.checkBoxesToContinue':
    'You must check the boxes to continue.',
  'instantFunding.error':
    'We were unable to link your bank account to Instant Funding.',
  'instantFunding.error.message':
    'Please try again or contact Customer Service at <tel>(888) 556-3483</tel>.',
  'instantfunding.draw.cardInfo': '{network} debit card ending in {lastFour}',
  'formInput.account.pw': 'New Password',
  'formInput.account.confirmPw': 'Confirm New Password',
  'formInput.accountNumber': 'Bank account number',
  'formInput.accountNumber.error': 'Invalid bank account number',
  'formInput.accountNumber.nonMatch': 'Account numbers do not match',
  'formInput.accountNumberConfirmation': 'Confirm bank account number',
  'formInput.routingNumber': '9-digit routing number',
  'formInput.routingNumber.error': 'Invalid routing number',
  'formInput.business.legalName': 'Legal Business Name',
  'formInput.business.address.lineOne': 'Business Street Address',
  'formInput.business.address.city': 'City',
  'formSelect.business.address.state': 'State',
  'formInput.business.address.postalCode': 'ZIP code',
  'formInput.business.phone': 'Business Phone',
  'formInput.business.inceptionDate': 'What year did you start your business?',
  'formSelect.business.legalEntityType': 'Legal Entity Type',
  'formInput.business.taxId': 'Business Tax ID',
  'formInput.grossAnnualRevenue': 'Estimated Gross Annual Revenue',
  'formInput.averageBankBalance': 'Average Bank Balance',
  'formInput.continueButton': 'Continue',
  'formInput.business.dbaName': 'Alternate Business Name',
  'formInput.email': 'Email',
  'formInput.firstName': 'First Name',
  'formInput.financeNeeds.fundingTimeframe': 'How soon do you need the money?',
  'formInput.financeNeeds.fundingUsage': 'How will you use the loan?',
  'formInput.financeNeeds.desiredAmountToBorrow': 'How much funding do you think you may need?',
  'formInput.financeNeeds.desiredAmountToBorrow.error': 'Please enter the funding amount you may need',
  'formSelect.financeNeeds.desiredFundsAvailability': 'How soon do you need the money?',
  'formInput.financeNeeds.desiredUseOfFunds': 'How will you use the loan?',
  'formInput.financeNeeds.desiredUseOfFunds.error': 'Please state the reason of using the loan.',
  'formInput.homePhone': 'Best Contact Number',
  'formInput.lastName': 'Last Name',
  'formRadio.business.shortTermFinancing.label':
    'Do you currently have other short-term financing?',
  'formRadio.business.shortTermFinancing.option.true': 'Yes',
  'formRadio.business.shortTermFinancing.option.false': 'No',
  'formRadio.business.dba.option.true': 'Yes',
  'formRadio.business.dba.option.false': 'No',
  'formRadio.business.dba.label': 'Do you do business under a different name?',
  'formRadio.business.dba.error': 'Error: Please select an option.',
  'formRadio.documentType.option.SIGNED_CONTRACT': 'Signed Contract',
  'formRadio.documentType.option.MONTHLY_STATEMENT': 'Monthly Statement',
  'formRadio.documentType.option.SIGNED_DISCLOSURE': 'Signed Disclosure',
  'formRadio.documentType.option.UNSIGNED_DISCLOSURE': 'Unsigned Disclosure',
  'formRadio.documentType.option.UNSIGNED_CONTRACT': 'Unsigned Contract',
  'formRadio.documentType.option.DRAW_DISCLOSURE': 'Draw Disclosure',
  'formRadio.documentType.option.BANK_STATEMENT': 'Bank Statement',
  'formRadio.documentType.option.TAX': 'Tax Document',
  'formRadio.documentType.option.UNCLASSIFIED': 'Unclassified Document',
  'formRadio.documentType.option.SIGNED_CONTRACT.value': 'SIGNED_CONTRACT',
  'formRadio.documentType.option.MONTHLY_STATEMENT.value': 'MONTHLY_STATEMENT',
  'formRadio.documentType.option.SIGNED_DISCLOSURE.value': 'SIGNED_DISCLOSURE',
  'formRadio.documentType.option.UNSIGNED_DISCLOSURE.value': 'UNSIGNED_DISCLOSURE',
  'formRadio.documentType.option.UNSIGNED_CONTRACT.value': 'UNSIGNED_CONTRACT',
  'formRadio.documentType.option.DRAW_DISCLOSURE.value': 'DRAW_DISCLOSURE',
  'formRadio.documentType.option.BANK_STATEMENT.value': 'BANK_STATEMENT',
  'formRadio.documentType.option.TAX.value': 'TAX',
  'formRadio.documentType.option.UNCLASSIFIED.value': 'UNCLASSIFIED',
  'formSelect.option.business.legalEntityType.1': 'Sole Proprietor',
  'formSelect.option.business.legalEntityType.2':
    'Limited Liability Company (LLC)',
  'formSelect.option.business.legalEntityType.3': 'Corporation',
  'formSelect.option.business.legalEntityType.4': 'General Partnership',
  'formSelect.option.business.legalEntityType.5': 'Limited Partnership (LP)',
  'formSelect.option.business.legalEntityType.6':
    'Limited Liability Partnership (LLP)',
  'formSelect.option.business.legalEntityType.7': 'Non-Profit',
  'formSelect.option.business.legalEntityType.1.value': 'SOLE_PROPRIETOR',
  'formSelect.option.business.legalEntityType.2.value': 'LLC',
  'formSelect.option.business.legalEntityType.3.value': 'CORPORATION',
  'formSelect.option.business.legalEntityType.4.value': 'GENERAL_PARTNERSHIP',
  'formSelect.option.business.legalEntityType.5.value': 'LP',
  'formSelect.option.business.legalEntityType.6.value': 'LLP',
  'formSelect.option.business.legalEntityType.7.value': 'NON_PROFIT',
  'renewalApp.BusinessInfo': 'Business Contact Information',
  'renewalApp.BusinessDetails': 'Business Details',
  'renewalApp.step.1': 'Step 1: Verify Business Information',
  'renewalApp.step.2': 'Step 2: Verify Owner Information',
  'renewalApp.step.3': 'Step 3: Review Bank Information',
  'renewalApp.step.final': 'Final Step: Provide Bank Information',
  'renewalApp.ownerInfo': 'Owner Contact Information',
  'loanApp.step.consent': 'Thanks for choosing OnDeck!',
  'loanApp.step.consent.heading': 'We’re ready to help you achieve your business goals. Submit your application now!',
  'loanApp.step.consent.locTitle': 'OnDeck Line of Credit',
  'loanApp.step.consent.locText': 'A revolving credit line you can draw from 24/7 to receive funds within seconds.* Great for keeping funds on hand.',
  'loanApp.step.consent.tlTitle': 'OnDeck Term Loan',
  'loanApp.step.consent.tlText': 'A one-time lump sum of cash with an eventual option to apply for more. Great for larger investments in your business.',
  'loanApp.step.bankConfirmation.heading': 'Is this your current business checking account?',
  'loanApp.step.bankConfirmation.subheading': 'We\'ll use this account to deposit funds and withdraw automatic payments.',
  'loanApp.step.bankConfirmation.bankText': 'Ending in {lastFour}',
  'loanApp.step.bankConfirmation.bankName': '{bankName}',
  'loanApp.step.bankConfirmation.previous': 'Previous',
  'loanApp.step.bankConfirmation.continue': 'Continue',
  'loanApp.step.bankInfo.subheading.yes': 'We’ll need to review the most recent 90 days of activity on your <b>business checking account ending in {accountLastFour}.</b>',
  'loanApp.step.bankInfo.subheading.no': 'We’ll need to review your most recent 90 days of bank activity. Provide information about your <b>preferred business checking account for OnDeck transactions.</b>',
  'loanApp.disclaimer.submit': 'By clicking “Submit Application”, you (i) consent to receiving telemarketing calls and messages, including calls using an automatic telephone dialing system, from OnDeck and those acting on its behalf at the telephone number you have provided above (including your cellular phone number); agree that this consent applies even if the number you have provided is currently on any state, federal, or corporate Do-Not-Call registry; and understand that you are not required to provide this consent as a condition of receiving any credit or services from OnDeck and that you may apply for business credit by contacting us directly; and (ii) acknowledge that you have read OnDeck’s <terms>Terms of Use</terms> and <private>Privacy Policy</private> and understand that you may opt out of receiving communications of your choice from OnDeck as provided in the Privacy Policy.',
  'loanApp.step.consent.button': 'Continue',
  'loanApp.disclaimer.submit': 'By clicking “Continue”, you (i) consent to receiving telemarketing calls and messages, including calls using an automatic telephone dialing system, from OnDeck and those acting on its behalf at the telephone number you have provided above (including your cellular phone number); agree that this consent applies even if the number you have provided is currently on any state, federal, or corporate Do-Not-Call registry; and understand that you are not required to provide this consent as a condition of receiving any credit or services from OnDeck and that you may apply for business credit by contacting us directly; and (ii) acknowledge that you have read OnDeck’s <terms>Terms of Use</terms> and <private>Privacy Policy</private> and understand that you may opt out of receiving communications of your choice from OnDeck as provided in the Privacy Policy.',
  'loanApp.disclaimer.instantFunding': '*Instant Funding requires registration and is subject to the <link>Instant Funding Terms & Conditions</link>. Instant Funding is limited to open lines of credit for draws between $1,000 - $10,000, and you can only make one draw request per day. Not all banks or debit card providers participate, and you must register a business debit card matching the information associated with your OnDeck account. Transfers are typically completed within 30 minutes, but may be subject to additional restrictions or delays.',
  'formInput.person.firstName': 'First Name',
  'formInput.person.lastName': 'Last Name',
  'formInput.person.address.lineOne': 'Home Address',
  'formInput.person.address.city': 'City',
  'formSelect.person.address.state': 'State',
  'formInput.person.address.postalCode': 'ZIP code',
  'formInput.person.homePhone': 'Best Contact Number',
  'formInput.person.ownershipPct': 'Business Ownership Percentage',
  'formRadio.bankConfirmation.option.previous': 'True',
  'formRadio.bankConfirmation.option.continue': 'False',
  'formRadio.bankConfirmation.checkingAccount.option.Yes': '<b>Yes</b>, this is my current business checking account.',
  'formRadio.bankConfirmation.checkingAccount.option.No': '<b>No</b>, this is not my current business checking account.',
  'formCheckbox.creditConsent':
    'By checking this box, you acknowledge that you have read and agreed to OnDeck’s <terms>Terms of Use</terms> and <credit>Credit Profile Agreement</credit> (which authorizes OnDeck to collect various types of information on your company, including background and credit checks, and credit card sales data).',
  'formCheckbox.step.creditConsent':
    '<b>By checking this box, you’re opting in to receive text messages</b> from ODK Capital, LLC ("OnDeck") regarding your application, marketing and/or promotions, and general information regarding our products and services. You are not required to opt in as a condition of purchasing any property, goods, or services. Message frequency varies. Standard message and data rates may apply. You can reply STOP to a text message from 35124 at any time to unsubscribe. Text HELP to 35124 for help. Review our <terms>Terms of Use</terms> and <private>Privacy Policy</private> for additional information.',
  'formCheckbox.step.eSignConsent':
    'I consent to OnDeck using my Social Security number to confirm my identity and conduct a soft credit inquiry. In rare circumstances where OnDeck needs to make a hard credit inquiry, they will advise me beforehand.',
  'formCheckbox.eSignConsent':
    'By checking this box, you agree that you have read and agree to OnDeck’s <consent>E-sign Consent Agreement</consent>.',
  'formCheckbox.ssnConsent': 'I consent to OnDeck using my Social Security number to confirm my identity and conduct a soft credit inquiry, which has no negative impact on your personal credit score.',
  'formRadio.person.isPrimaryOwner.label':
    'Does your business have an individual with significant responsibility for managing the legal entity other than you?',
  'formRadio.person.isPrimaryOwner.option.true': 'Yes',
  'formRadio.person.isPrimaryOwner.option.false': 'No',
  'formInput.business.lenderName': 'Additional Lender Name',
  'formInput.business.remaining': 'Remaining Balance',
  'renewalApp.received.title': 'We received your application.',
  'renewalApp.received.para1':
    'We’ll contact you within 1 - 2 business days to collect bank statements and discuss next steps.',
  'renewalApp.received.questions':
    'If you have any questions, please call Customer Service at <tel>(888) 556-3483</tel>.',
  'renewalApp.received.button': 'Return to Dashboard',
  'renewalApp.review.title':
    'We’re analyzing your business information. This may take a few minutes.',
  'renewalApp.review.questions': 'Questions?',
  'renewalApp.review.para1':
    'We’re here to help. We also take applications over the phone.',
  'renewalApp.review.button': 'Return to Dashboard',
  'renewalApp.review.phone': '(888) 556-3483',
  'renewalApp.review.emailSupport': 'Email Support',
  'renewalApp.review.email': 'CustomerService@ondeck.com',
  'formSelect.option.financeNeeds.desiredFundsAvailability.1.value': 'ONE_DAY',
  'formSelect.option.financeNeeds.desiredFundsAvailability.1': 'One day',
  'formSelect.option.financeNeeds.desiredFundsAvailability.2.value': 'TWO_TO_THREE_DAYS',
  'formSelect.option.financeNeeds.desiredFundsAvailability.2': 'Two to three days',
  'formSelect.option.financeNeeds.desiredFundsAvailability.3.value': 'ONE_WEEK',
  'formSelect.option.financeNeeds.desiredFundsAvailability.3': 'One week',
  'formSelect.option.financeNeeds.desiredFundsAvailability.4.value': 'TWO_WEEKS',
  'formSelect.option.financeNeeds.desiredFundsAvailability.4': 'Two weeks',
  'formSelect.option.financeNeeds.desiredFundsAvailability.5.value': 'THREE_TO_FOUR_WEEKS',
  'formSelect.option.financeNeeds.desiredFundsAvailability.5': 'Three to four weeks',
  'formSelect.option.financeNeeds.desiredFundsAvailability.6.value': 'OVER_ONE_MONTH',
  'formSelect.option.financeNeeds.desiredFundsAvailability.6': 'Over one month',
  'formSelect.option.business.address.state.1.value': 'AL',
  'formSelect.option.business.address.state.2.value': 'AK',
  'formSelect.option.business.address.state.3.value': 'AZ',
  'formSelect.option.business.address.state.4.value': 'AR',
  'formSelect.option.business.address.state.5.value': 'CA',
  'formSelect.option.business.address.state.6.value': 'CO',
  'formSelect.option.business.address.state.7.value': 'CT',
  'formSelect.option.business.address.state.8.value': 'DC',
  'formSelect.option.business.address.state.9.value': 'DE',
  'formSelect.option.business.address.state.10.value': 'FL',
  'formSelect.option.business.address.state.11.value': 'GA',
  'formSelect.option.business.address.state.12.value': 'HI',
  'formSelect.option.business.address.state.13.value': 'ID',
  'formSelect.option.business.address.state.14.value': 'IL',
  'formSelect.option.business.address.state.15.value': 'IN',
  'formSelect.option.business.address.state.16.value': 'IA',
  'formSelect.option.business.address.state.17.value': 'KS',
  'formSelect.option.business.address.state.18.value': 'KY',
  'formSelect.option.business.address.state.19.value': 'LA',
  'formSelect.option.business.address.state.20.value': 'ME',
  'formSelect.option.business.address.state.21.value': 'MD',
  'formSelect.option.business.address.state.22.value': 'MA',
  'formSelect.option.business.address.state.23.value': 'MI',
  'formSelect.option.business.address.state.24.value': 'MN',
  'formSelect.option.business.address.state.25.value': 'MS',
  'formSelect.option.business.address.state.26.value': 'MO',
  'formSelect.option.business.address.state.27.value': 'MT',
  'formSelect.option.business.address.state.28.value': 'NE',
  'formSelect.option.business.address.state.29.value': 'NV',
  'formSelect.option.business.address.state.30.value': 'NH',
  'formSelect.option.business.address.state.31.value': 'NJ',
  'formSelect.option.business.address.state.32.value': 'NM',
  'formSelect.option.business.address.state.33.value': 'NY',
  'formSelect.option.business.address.state.34.value': 'NC',
  'formSelect.option.business.address.state.35.value': 'ND',
  'formSelect.option.business.address.state.36.value': 'OH',
  'formSelect.option.business.address.state.37.value': 'OK',
  'formSelect.option.business.address.state.38.value': 'OR',
  'formSelect.option.business.address.state.39.value': 'PA',
  'formSelect.option.business.address.state.40.value': 'RI',
  'formSelect.option.business.address.state.41.value': 'SC',
  'formSelect.option.business.address.state.42.value': 'SD',
  'formSelect.option.business.address.state.43.value': 'TN',
  'formSelect.option.business.address.state.44.value': 'TX',
  'formSelect.option.business.address.state.45.value': 'UT',
  'formSelect.option.business.address.state.46.value': 'VT',
  'formSelect.option.business.address.state.47.value': 'VA',
  'formSelect.option.business.address.state.48.value': 'WA',
  'formSelect.option.business.address.state.49.value': 'WV',
  'formSelect.option.business.address.state.50.value': 'WI',
  'formSelect.option.business.address.state.51.value': 'WY',
  'formSelect.option.business.address.state.1': 'AL',
  'formSelect.option.business.address.state.2': 'AK',
  'formSelect.option.business.address.state.3': 'AZ',
  'formSelect.option.business.address.state.4': 'AR',
  'formSelect.option.business.address.state.5': 'CA',
  'formSelect.option.business.address.state.6': 'CO',
  'formSelect.option.business.address.state.7': 'CT',
  'formSelect.option.business.address.state.8': 'DC',
  'formSelect.option.business.address.state.9': 'DE',
  'formSelect.option.business.address.state.10': 'FL',
  'formSelect.option.business.address.state.11': 'GA',
  'formSelect.option.business.address.state.12': 'HI',
  'formSelect.option.business.address.state.13': 'ID',
  'formSelect.option.business.address.state.14': 'IL',
  'formSelect.option.business.address.state.15': 'IN',
  'formSelect.option.business.address.state.16': 'IA',
  'formSelect.option.business.address.state.17': 'KS',
  'formSelect.option.business.address.state.18': 'KY',
  'formSelect.option.business.address.state.19': 'LA',
  'formSelect.option.business.address.state.20': 'ME',
  'formSelect.option.business.address.state.21': 'MD',
  'formSelect.option.business.address.state.22': 'MA',
  'formSelect.option.business.address.state.23': 'MI',
  'formSelect.option.business.address.state.24': 'MN',
  'formSelect.option.business.address.state.25': 'MS',
  'formSelect.option.business.address.state.26': 'MO',
  'formSelect.option.business.address.state.27': 'MT',
  'formSelect.option.business.address.state.28': 'NE',
  'formSelect.option.business.address.state.29': 'NV',
  'formSelect.option.business.address.state.30': 'NH',
  'formSelect.option.business.address.state.31': 'NJ',
  'formSelect.option.business.address.state.32': 'NM',
  'formSelect.option.business.address.state.33': 'NY',
  'formSelect.option.business.address.state.34': 'NC',
  'formSelect.option.business.address.state.35': 'ND',
  'formSelect.option.business.address.state.36': 'OH',
  'formSelect.option.business.address.state.37': 'OK',
  'formSelect.option.business.address.state.38': 'OR',
  'formSelect.option.business.address.state.39': 'PA',
  'formSelect.option.business.address.state.40': 'RI',
  'formSelect.option.business.address.state.41': 'SC',
  'formSelect.option.business.address.state.42': 'SD',
  'formSelect.option.business.address.state.43': 'TN',
  'formSelect.option.business.address.state.44': 'TX',
  'formSelect.option.business.address.state.45': 'UT',
  'formSelect.option.business.address.state.46': 'VT',
  'formSelect.option.business.address.state.47': 'VA',
  'formSelect.option.business.address.state.48': 'WA',
  'formSelect.option.business.address.state.49': 'WV',
  'formSelect.option.business.address.state.50': 'WI',
  'formSelect.option.business.address.state.51': 'WY',
  'form.selection.error': 'Selection is required.',
  'form.checkbox.error': 'You must check the box to continue.',
  'formInput.averageBankBalance.error': 'Average bank balance is required.',
  'formInput.business.address.lineOne.error':
    'Business street address is required.',
  'formInput.business.address.city.error': 'City is required.',
  'formInput.business.address.postalCode.error': 'ZIP code is required.',
  'formInput.business.phone.error': 'Business phone number is required.',
  'formInput.business.inceptionDate.error': 'Date is required.',
  'formInput.business.taxId.error': 'Tax ID is required.',
  'formInput.email.error': 'Email address is required.',
  'formInput.firstName.error': 'First name is required.',
  'formInput.grossAnnualRevenue.error': 'Estimated gross annual revenue is required.',
  'formInput.homePhone.error': 'Phone number is required.',
  'formInput.lastName.error': 'Last name is required.',
  'formInput.person.firstName.error': 'First name is required.',
  'formInput.person.lastName.error': 'Last name is required.',
  'formInput.person.address.lineOne.error': 'Home address is required.',
  'formInput.person.address.city.error': 'City is required.',
  'formInput.person.address.state.error': 'State is required.',
  'formInput.person.address.postalCode.error': 'ZIP code is required.',
  'formInput.person.homePhone.error': 'Phone number is required.',
  'formInput.person.ownershipPct.error': 'Ownership percentage is required.',
  'formInput.business.lenderName.error': 'Lender name is required.',
  'formInput.business.remaining.error': 'Remaining balance is required.',
  'formInput.business.dbaName.error': 'Alternate business name is required.',
  'formInput.business.legalName.error': 'Legal business name is required.',
  'formInput.business.legalName.helperText': 'If your business name changed, contact your loan advisor to complete your application.',
  'common.continue': 'Continue',
  'calculator.calculate': 'Calculate Offer',
  'calculator.months': 'months',
  'calculator.paymentFreq': 'Payment Frequency',
  'calculator.paymentFreqChoices': 'Select Payment Frequency',
  'calculator.termAmt': 'Select Loan Amount',
  'calculator.termChoices': 'Select Term (in Months)',
  'identityVerification.alert': 'Please review the items above',
  'identityVerification.button': "Yes, that's right",
  'identityVerification.subtitle': 'For your security, please answer these three questions that only you know the answers to:',
  'identityVerification.title': "Okay! Now let's confirm your identity.",
  'offerReview.title': 'Let\'s review your offer!',
  'offerReview.subtext': 'This offer is contingent on confirmation and verification of your business and account information. By continuing to "Accept My Offer", I consent that I have read and agreed to {agreementLink}',
  'offerReview.lateFee': 'Late Fee',
  'offerReview.totalLoan': 'Total Loan Amount',
  'offerReview.totalLoan.prefix': '(This includes your principal balance amount and ',
  'offerReview.totalLoan.suffix': ' in new funds.)',
  'offerReview.paymentFrequency.Daily': 'Daily Payments',
  'offerReview.paymentFrequency.Weekly': 'Weekly Payments',
  'offerReview.paymentFrequency.Monthly': 'Monthly Payments',
  'offerReview.paymentFrequency.DAILY': 'Daily Payments',
  'offerReview.paymentFrequency.WEEKLY': 'Weekly Payments',
  'offerReview.paymentFrequency.MONTHLY': 'Monthly Payments',
  'offerReview.paymentFrequency.1': '(About ',
  'offerReview.paymentFrequency.2': '/month for {term} months.)',
  'offerReview.paymentFrequency.tooltip.Daily': 'Fixed daily payments will be automatically debited from your business bank account each business day (i.e., Monday through Friday, but excluding any national and bank holidays). Daily auto-payments help reduce the strain of making larger monthly payments.',
  'offerReview.paymentFrequency.tooltip.Monthly': 'Your fixed monthly payment will be automatically debited from your account the same day each month. That day is based on the day of the month disbursement of your loan funds were initiated by Lender (or if such disbursement day was not a Business Day, the next Business Day).',
  'offerReview.paymentFrequency.tooltip.Weekly': 'Your fixed weekly payment will be automatically debited from your account the same day each week. That day is based on the day of the week disbursement of your loan funds were initiated by Lender (or if such disbursement day was not a Business Day, the next Business Day).',
  'offerReview.paymentFrequency.tooltip.DAILY': 'Fixed daily payments will be automatically debited from your business bank account each business day (i.e., Monday through Friday, but excluding any national and bank holidays). Daily auto-payments help reduce the strain of making larger monthly payments.',
  'offerReview.paymentFrequency.tooltip.MONTHLY': 'Your fixed monthly payment will be automatically debited from your account the same day each month. That day is based on the day of the month disbursement of your loan funds were initiated by Lender (or if such disbursement day was not a Business Day, the next Business Day).',
  'offerReview.paymentFrequency.tooltip.WEEKLY': 'Your fixed weekly payment will be automatically debited from your account the same day each week. That day is based on the day of the week disbursement of your loan funds were initiated by Lender (or if such disbursement day was not a Business Day, the next Business Day).',
  'offerReview.returnedPaymentFee': 'Returned Payment Fee',
  'offerReview.locApprovedCreditLimit': 'Approved Credit Limit',
  'offerReview.rid': 'Required Initial Draw',
  'offerReview.monthlyFee': 'Monthly Fee',
  'offerReview.fastFunding': 'Fast Funding',
  'offerReview.drawInterest': 'Only pay interest on what you draw',
  'offerReview.creditHistory': 'Build business credit history with on-time payments',
  'offerReview.acceptOffer': 'Accept My Offer',
  'offerConfig.title.prefix': "Good news! You've been approved for a loan up to ",
  'offerConfig.full.calculator.heading': 'Offer Calculator',
  'offerConfig.simple.selectOffer.customize': 'Customize Offer',
  'offerConfig.simple.selectOffer.heading': 'Select Offer',
  'offerPreview.apr': 'APR',
  'offerPreview.apr.tooltip': 'This is the cost of the Loan — including total interest and other fees — expressed as a yearly rate. APR takes into account the amount and timing of capital you receive, fees you pay and the periodic payments you make. While APR can be used for comparison purposes, it is not an interest rate and is not used to calculate your interest expense.',
  'offerPreview.capital': 'Total Cost of Capital',
  'offerPreview.capital.tooltip': 'This is the total amount that you will pay in interest and other fees for the loan. The amount does not include fees and other charges you can avoid, such as late payment fees and returned payment fees.',
  'offerPreview.cod': 'Cents on the Dollar (CoD)',
  'offerPreview.codTooltipText': "“Cents on the dollar” shows the interest you'll pay per dollar you borrow (not including fees).  i.e. If you have a $10,000 loan and you're paying $0.40 on the dollar, you'll end up paying $4,000 in interest.  The example above is assuming that you made a draw on the full line of credit, that the draw amount is paid back within 12 months making minimum payments, and no additional draws are taken before the initial draw is paid down.  Should you take an additional draw(s) prior to the initial draw being paid down, the CoD will change based on the new total draw amount.  The total CoD you pay will depend on how long you take to repay the draw and whether you take a new draw prior to the full repayment of the current draw. Additional draws may increase the total cents on the dollar repaid. However, your APR will never change.",
  'offerPreview.costAndFees': 'Cost & Fees',
  'offerPreview.details': 'Details',
  'offerPreview.disbursement': 'Estimated Net Amount to be Deposited',
  'offerPreview.disbursementNew': 'Estimated disbursement Amount',
  'offerPreview.disbursement.tooltip': 'This is the amount that will be deposited into your designated account. It is the loan amount less (a) the origination fee and (b) the remaining principal balance on an existing loan, if applicable. All remaining interest on an existing loan will be forgiven.',
  'offerPreview.estimatedPaymentAmount': 'Estimated Payment Amount',
  'offerPreview.header': 'Offer Preview',
  'offerPreview.loanAmount': 'Loan Amount',
  'offerPreview.months': 'months',
  'offerPreview.numberOfPayments': 'Number of Payments',
  'offerPreview.originationFee': 'Origination Fee',
  'offerPreview.payment': 'Payment',
  'offerPreview.paymentFrequency': 'Payment Frequency',
  'offerPreview.prepaymentDiscount': 'Early Repayment Discount',
  'offerPreview.prevInterest': 'Previous Loan Interest',
  'offerPreview.principalBalance': 'Principal Balance',
  'offerPreview.repaymentAmount': 'Total Repayment Amount',
  'offerPreview.selectedDraw': 'Selected Draw',
  'offerPreview.selectedDrawDetails': 'Selected Draw Details',
  'offerPreview.term': 'Term',
  'offerPreview.totalCost': 'Total Cost of Capital',
  'offerPreview.waived': 'Waived',
  'offerPreview.termTooltipText': 'Your line of credit will remain open. However, when you take a draw you will have {term} months to repay that draw.',
  'offerPreview.requiredInitialDrawTooltipText': "We don't require you to draw from your line of credit immediately. When you do decide to access your funds, we have a minimum draw of $1,000.",
  'originationFee.tooltip': 'This covers the cost of underwriting, processing the loan, disbursing and collecting payments, as well as reporting monthly to business credit bureaus. Based on underwriting, it is always 2.5% - 4% of the loan amount based on risk.',
  'ppd.100.available': '*100% early repayment discount available.',
  'selectOffer.term': 'Term',
  'selectOffer.weeklyPayment': 'Weekly Payment',
  'selectOffer.dailyPayment': 'Daily Payment',
  'prepayment.reduction.ppd.100.label': '100% Early Repayment Discount',
  'prepayment.reduction.ppd.25.label': '25% Early Repayment Discount',
  'prepayment.select.100.body': 'We\'ll forgive 100% of outstanding interest, and you\'ll only be responsible for the remaining principal balance.',
  'prepayment.select.100.title': '100% early repayment discount',
  'prepayment.select.25.body': 'We\'ll forgive 25% of outstanding interest, but you will be required to pay 75% of outstanding interest as of the prepayment date.',
  'prepayment.select.25.title': '25% early repayment discount',
  'prepayment.tooltip.ppd.100': 'If you choose to prepay your loan amount in full before the end of the term, 100% of outstanding interest will be forgiven, and you would only be responsible for the remaining principal balance. If you prepay your loan, no additional fees will be charged.',
  'prepayment.tooltip.ppd.25': 'If you choose to prepay your loan amount in full, 25% of outstanding interest will be forgiven, but you will be required to pay 75% of outstanding interest as of the prepayment date. If you prepay your loan, no additional fees will be charged.',
  'prepayment.select.header.interestOwed': 'Interest owed',
  'prepayment.select.header.interestWaived': 'Interest waived',
  'prepayment.select.header.remainingInterest': 'Remaining interest',
  'prepayment.select.example': 'Example',
  'prepayment.link.selectDifferentOffer': '< Select a Different Offer',
  'offerReview.modify': 'Modify Offer',
  'reviewPage.disbursementAmt.label': 'Disbursement Amount',
  'reviewPage.netAmt.label': 'Net amount to be deposited',
  'tooltip.disbursement.amtGivenToYou': 'This is your total loan amount, minus the origination fee.',
  'tooltip.disbursement.disbursementAmt': 'This is your total loan amount, minus the origination fee.',
  'tooltip.disbursement.netAmt': 'This is the amount that will be deposited into your designated account. It is the loan amount less (a) the origination fee and (b) the remaining principal balance on an existing loan, if applicable. All remaining interest on an existing loan will be forgiven.',
  'tooltip.originationFee': 'This covers the cost of underwriting, processing the loan, disbursing and collecting payments, as well as reporting monthly to business credit bureaus. Based on underwriting, it is always 2.5% - 4% of the loan amount based on risk.',
  'totalCostOfCapital.tooltip': 'This is the total amount that you will pay in interest and other fees for the loan. The amount does not include fees and other charges you can avoid, such as late payment fees and returned payment fees.',
  'totalPaymentAmount.label': 'Total Repayment Amount',
  'salesAgent.title.label': 'Loan Advisor',
  'salesAgent.fax.label': 'Fax: {faxNumber}',
  'salesAgentInfo.error.loading': 'Looks like we had an issue loading your Sales Agent Information. Please refresh the page to try again.',
  'salesAgentInfo.phone': 'Call {phoneNumber}',
  'termsAndAgreements.agreeAndFunds': 'Agree and get funds',
  'termsAndAgreements.agreementHeading': 'Business Loan and Security Agreement',
  'termsAndAgreements.agreementHeadingLOC': 'Business Line of Credit Agreement',
  'termsAndAgreements.agreementInstructions': 'Please read the below agreement and accept the terms to confirm your funds.',
  'termsAndAgreements.agreementTitle': 'Business Loan and Security Agreement, supplement, and authorization for direct deposit and direct payments',
  'termsAndAgreements.agreementTitleLOC': 'Business Line of Credit Agreement, supplement, and authorization for direct deposit and direct payments',
  'termsAndAgreements.checkbox1': 'I hereby, as a duly authorized agent of Borrower, and in my individual and personal capacity as Guarantor, affirm that I have read and understand the terms and conditions of, consent to, and agree to be bound by, the Business Loan and Security Agreement (inclusive of the Guaranty therein and mandatory arbitration provision), the accompanying Business Loan and Security Agreement Supplement, and the accompanying Authorization Agreement for Direct Deposit (ACH Credits) and Direct Payments (ACH Debits).',
  'termsAndAgreements.checkbox1LOC': 'I hereby, as a duly authorized agent of Borrower, and in my individual and personal capacity as Guarantor, affirm that I have read and understand the terms and conditions of, consent to, and agree to be bound by, the Business Line of Credit Agreement (inclusive of the Guaranty therein and mandatory arbitration provision), the accompanying Business Line of Credit Agreement for Direct Deposit (ACH Credits) and Direct Payments (ACH Debits).',
  'termsAndAgreements.checkbox2': 'I hereby, as a duly authorized agent of Borrower, and in my individual and personal capacity as Guarantor, represent and warrant that neither Borrower, nor myself individually as Guarantor, intend to file for reorganization or liquidation under the bankruptcy or reorganization laws of any jurisdiction within 6 months of today’s date. Furthermore, I hereby, as a duly authorized agent of Borrower, represent that Borrower is not presently insolvent within the meaning of the Uniform Commercial Code as well as the United States Bankruptcy Code.',
  'termsAndAgreements.checkbox3': "By checking here I, as a duly authorized agent of the Borrower, and in my individual and personal capacity as Guarantor, agree that I have read and agree to OnDeck's <esignConsent>E-Sign Consent Agreement</esignConsent>, <applicationConsent>Application Agreement</applicationConsent>, <creditCheckLink>Credit Profile Agreement</creditCheckLink>, and <privacyLink>Privacy Policy</privacyLink>.",
  'termsAndAgreements.checkbox4': 'By checking here, I acknowledge that if I am a servicemember and am called into active duty while my loan with OnDeck is outstanding, I will promptly notify OnDeck. Upon notice, OnDeck will reduce the rate of my loan to 4.0% APR per the requirements of the Servicemember Civil Relief Act 50 U.S.C. § 3937. If I have a line of credit, OnDeck will be required to credit and debit $1 from my account within 30 days of my deployment to implement such reduction, which will not change the outstanding balance of my loan immediately prior to such reduction.',
  'termsAndAgreements.checkboxErrorMessage': 'Please accept the terms listed above.',
  'termsAndAgreements.contractPdfName': 'contract',
  'termsAndAgreements.disclosureCheckbox': 'In accordance with state law, please refer to the following for the specific terms associated with your offer: <a>OFFER SUMMARY - Business Installment Loan</a>. Applicable law requires this information to be provided to you to help you make an informed decision. By checking here, you are confirming that you received this information.',
  'termsAndAgreements.disclosureCheckboxLOC': 'In accordance with state law, please refer to the following for the specific terms associated with your offer: <a>OFFER SUMMARY - Business Line of Credit</a>. Applicable law requires this information to be provided to you to help you make an informed decision. By checking here, you are confirming that you received this information.',
  'termsAndAgreements.disclosureErrorMessage': 'Please first open and consent to these disclosures.',
  'termsAndAgreements.disclosureInstructions': 'Click "OFFER SUMMARY" below and view state disclosures outlining your offer details. Once complete, check the box and continue.',
  'termsAndAgreements.disclosurePdfName': 'disclosure',
  'termsAndAgreements.disclosureTitle': 'Offer Summary & Consent',
  'termsAndAgreements.sameDayFundingTooltip': 'Same Day Funding is only available in certain states, for term loans up to $100K. Eligibility window is Monday-Friday before 10:30am EST. If checkout is done before 10:30am EST, funds will be available by 5pm local time the same day. If checkout is done after 10:30am EST, or on a weekend or bank holiday, the loan will not qualify for Same Day Funding and funds will be deposited within 2-3 business days.',
  'termsAndAgreements.subtitle': 'Once you sign your agreement, funds will be deposited in your bank account in 2-3 business days, or as soon as 5pm today with Same Day Funding.',
  'termsAndAgreements.title': 'Last step: review and sign to get your funds.',

  // NOTE: Whenever updating any text in this block, be sure to also update the date in Terms.tsx
  'termsPage.updated': 'Updated and effective { date }', // Will be formatted like "April 22, 2021"
  'termsPage.1.header': '1. OnDeck Terms and Conditions',
  'termsPage.1.body': 'The OnDeck service and website (each as defined below) are operated by On Deck Capital, Inc., ODK Capital, LLC, or a subsidiary of affiliate thereof (collectively, “OnDeck”, “we”, or “us”). OnDeck is in the business of providing customers with small business loans, lines of credit and related services (“Service”). OnDeck is not a broker, investment advisor or financial planner, and we do not provide financial, securities, legal or tax advice. Before making any decision or implementing any strategy you should consider obtaining additional information and advice from your accountant, attorney, and/or other advisors. “You” is defined as the owner of the company completing the application on behalf of the company seeking credit. Unless otherwise noted, any agreements, authorizations or consents made by you or permissions granted to you hereunder also apply and are binding on your company seeking credit directly from OnDeck, or from a bank that originates credit through the OnDeck platform (“OnDeck Banking Partner”).',
  'termsPage.2.header': '2. Accepting the Terms',
  'termsPage.2.body.1': 'By using the information, tools, features and functionality located at ondeck.com or subdomains (the “Website”) or by submitting an application for credit to OnDeck, you signify that you have read, understand and agree to be bound by these terms and conditions (the “Agreement”) for any and all applications for credit to OnDeck or an OnDeck Banking Partner. You further agree all information being provided by your company as the borrower and/or by you (either as a guarantor or as an authorized representative of your company) as part of the application process for the Loan is true and complete.',
  'termsPage.2.body.2': 'You may not use the Service and you may not accept this Agreement if you are under 18 years of age. You represent that you have the capacity to bind the company or entity applying for credit, on behalf of such company or entity. You understand that, other than with regard to loans under the SBA Paycheck Protection Program (“PPP loans”), (a) we will require you to sign a personal guarantee for the credit obtained by your company from OnDeck, and (b) for term loans, we will take a general lien on your business’s assets. Before you continue, you should print or save a copy of this Agreement for your records.',
  'termsPage.2.body.3': 'In addition to this Agreement you and your company may enter into other agreements, including a loan agreement or promissory note that will govern the terms of your company’s loan or line of credit and use of the Service. If there is a conflict between this Agreement and such other agreements that is applicable to specific aspects of the Service, the other agreement shall govern with respect to those specific aspects.',
  'termsPage.2.body.4': 'OnDeck may make changes to this Agreement from time to time. If we do this, the date at the top of this page indicates when the latest revisions were made. Your continued use of the Service or the Website will constitute acceptance of such revisions.',
  'termsPage.3.header': '3. Credit and Background Check Authorization',
  'termsPage.3.body': 'You understand and agree that OnDeck and our agents and assignees are authorized to contact third parties to conduct background checks and other investigative reports, including but not limited to your business credit card sales data as described below, and make credit report inquiries (including requesting personal credit bureau reports about you and any other owner(s) of the business who will be providing a personal guarantee, and business credit bureaus about your company, in either case, from credit reporting agencies and other sources) or for any other lawful purpose. OnDeck has such authority for the foregoing in connection with any extension of credit to the company on whose behalf you are applying, conducting loan file updates, ongoing loan reviews, renewal of financing, or referral of your business to third party lenders. Upon your written request, we will advise you if we obtained a credit report. You understand and agree that OnDeck is making no commitment or guarantee that any product, evaluation, or guidance provided by OnDeck will result in an approval of credit from OnDeck or any OnDeck Banking Partner.',
  'termsPage.4.header': '4. Privacy, Personal and Company Information',
  'termsPage.4.body': 'For information about OnDeck’s data protection practices, please read our Privacy Policy, which can be accessed on the Website, as modified from time to time, which is hereby incorporated into this Agreement. The Privacy Policy explains how OnDeck collects and treats your personal and company information, and protects your privacy, when you access OnDeck and use the Service. The policy may be updated from time to time at our discretion. Changes will be effective upon posting to the Website.',
  'termsPage.5.header': '5. Identity Verification',
  'termsPage.5.body.1': 'To help the government fight the funding of terrorism and money laundering activities, federal law requires financial institutions to obtain, verify, and record identifying information that identifies each person and entity applying for credit. Therefore, when your company applies for credit, we ask for your name, address, and other information that allows us to identify you and your company. We may also ask for a driver’s license or other identifying documents. You allow us to obtain such information and share the information with third parties to help us verify you and your company’s identity.',
  'termsPage.5.body.2': 'In connection with the provision of the Service, users may submit financial information from their business banking account(s) to OnDeck. OnDeck and OnDeck Banking Partners use this information for lending decisions and may require updates to monitor the performance of customers on an ongoing basis. With the Service, users may direct OnDeck to retrieve your company information maintained online or otherwise by third-party financial institutions or organizations authorized to house such information.',
  'termsPage.6.header': '6. Proprietary Rights',
  'termsPage.6.body.1': 'All content on the Website or used by the Service, including but not limited to text, names, designs, pictures, information and software are the proprietary property of OnDeck with all rights reserved.',
  'termsPage.6.body.2': 'You are permitted to use content delivered to you through the Service or Website only on the Service. You may not copy, reproduce, modify, distribute, or create derivative works from this content. Further, you agree not to reverse engineer or reverse compile any of the Service technology.',
  'termsPage.7.header': '7. Electronic Communications',
  'termsPage.7.body': 'You hereby consent to receive by electronic means, (or in writing through postal mail or email), this Agreement, communications, disclosures and notices, including and any and all disclosures and/or notices required to be given by applicable law or regulation. You also consent to allow OnDeck, or an OnDeck Banking Partner, to respond to any inquiries by e-mail, at the email address you have provided, regardless of the format of the original inquiry. You must also agree to our ESign consent during the online application process.',
  'termsPage.8.header': '8. Telephone Communications',
  'termsPage.8.body': 'You authorize OnDeck and those acting on its behalf to deliver or cause to be delivered advertisements or telemarketing messages, including calls, text messages, and SMS and MMS messages, using an automatic telephone dialing system (often referred to as an autodialer) or an artificial or prerecorded voice, to the telephone numbers you have provided in your application for credit to OnDeck (including your cellular phone numbers). You agree that this consent applies even if the numbers you have provided are currently on any state, federal, or corporate Do-Not-Call registry. You understand that you are not required to provide this consent as a condition of receiving any credit or services from OnDeck, and that you may apply for business credit by contacting us directly. You may opt out of receiving calls and marketing from OnDeck and its affiliates, marketing partners, agents and others as provided in the Privacy Policy or by contacting us directly.',
  'termsPage.9.header': '9. Loan Return Policy',
  'termsPage.9.body': 'You agree that you may not return any loan or other financial product that you take from OnDeck, or an OnDeck Banking Partner, except at OnDeck’s or the OnDeck Banking Partner’s sole discretion, it being understood that in no event will you be able to return any such product after 5:00 p.m. EST on the second business day following the initiation of disbursement of funds by OnDeck, and no returns of renewal loans or lines of credit will be permitted.',
  'termsPage.10.header': '10. Third Party Sites or Content',
  'termsPage.10.body.1': 'The Website or other materials sent to you under the Service may contain links to other Websites (“Third Party Sites”), as well as content from third parties. OnDeck is not responsible for such Third Party Sites or content from third parties, including without limitation accuracy, reliability, offensiveness, or appropriateness. If you decide to leave the Website and access Third Party Sites or third party content, this Agreement and OnDeck policies no longer apply.',
  'termsPage.10.body.2': 'In addition, if any such link is to another capital provider or lender, OnDeck acts solely as a referrer and does not guarantee or underwrite the products or services, including credit products, provided by such capital provider or lender. You will be deemed a customer of that capital provider or lender with respect to any products or services provided by such capital provider or lender. OnDeck has no role in, nor any liability for any decision made or product or service provided by such other capital provider or lender.',
  'termsPage.11.header': '11. Miscellaneous',
  'termsPage.11.limitationOnLiability.header': 'Limitation on Liability',
  'termsPage.11.limitationOnLiability.body': 'TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA, ARISING FROM YOUR USE OF THE WEBSITE OR THE SERVICES, INCLUDING THE UNAVAILABILITY OF THE SERVICES FOR ANY REASON, OR ANY SYSTEM FAILURE OR MALFUNCTION ASSOCIATED WITH THE SERVICES, AND INCLUDING ANY THIRD PARTY CLAIMS, WHETHER BASED ON THEORIES OF BREACH OF CONTRACT, TORT, PRODUCT LIABILITY, OR BREACH OF WARRANTY. THESE LIMITATIONS OF LIABILITY WILL APPLY EVEN IF WE ARE ADVISED ON THE POSSIBILITY OF SUCH DAMAGES OR CLAIMS. THE PARTIES UNDERSTAND THAT THE SERVICES BY US WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.',
  'termsPage.11.warranty.header': 'Warranty',
  'termsPage.11.warranty.body': 'WE DISCLAIM ANY WARRANTY OF MERCHANT LIABILITY OR FITNESS FOR A PARTICULAR PURPOSE, ANY LIABILITY FOR ERRORS OR OMISSIONS IN ANY INFORMATION PROVIDED AS PART OF THE SERVICES OR ON THE WEBSITE AND ANY WARRANTIES REGARDING THE OPERABILITY OF THE SERVICES OR LIABILITY FOR ANY SERVICE INTERRUPTIONS OR SYSTEM FAILURES THAT MAY AFFECT THE SERVICES AT ANY TIME.',
  'termsPage.11.governingLaw.header': 'Governing Law; Venue and Jurisdiction',
  'termsPage.11.governingLaw.body': 'By accessing the Website or using Services, you agree that the laws of the state of Virginia (or the home state of the OnDeck Banking Partner), without regard to any principles of conflict of laws that would require or permit the application of laws of any other jurisdiction, will govern this Agreement or your use of the Services.',
  'termsPage.ohio': '<underline>For Ohio applicants</underline>: You understand that the Ohio laws against discrimination require that all creditors make credit equally available to all credit worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio Civil Rights Commission administers compliance with this law.',
};

export const offerStateRegs  = {
  ...merchantPortalText,
  'offerReview.totalLoan': 'Total Funding Provided',
  'offerPreview.capital' : 'Finance Charge',
  'offerPreview.loanAmount': 'Funding Provided',
  'offerPreview.totalCost': 'Finance Charge',
  'offerPreview.repaymentAmount': 'Total Payment Amount',
  'offerPreview.apr': 'Annual Percentage Rate (APR)',
  'calculator.termAmt': 'Select Funding Provided',
  'totalPaymentAmount.label': 'Total Payment Amount',
  'offerPreview.apr.tooltip': 'APR is the cost of your financing expressed as a yearly rate. APR includes the amount and timing of the funding you receive, interest and other finance charges you pay and the payments you make. Your APR is not an interest rate. Your APR may be higher than your interest rate because APR incorporates interest costs and other finance charges.',
};
