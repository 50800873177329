import ExclamationIcon from './ExclamationCircle.svg';

type ImageProps = JSX.IntrinsicElements['div'] & {
  width?: string;
  height?: string;
  alt?: string;
}

export const ExclamationCircle = ({width = "16px", height = "auto", alt = "Exclamation Circle", ...props}: ImageProps) => 
<div {...props}><img src={ExclamationIcon} width={width} height={height} alt={alt} style={{paddingBottom:'1px'}} /></div>
export default ExclamationCircle;

