/// <reference types="vite/client" />
import useSWR from 'swr';
import useParties from '../useParties/useParties';
import { findObjWithType } from '@odmonorepo/odfunctions';
import { Address, BusinessProfile } from 'merchant-api/types';

export interface BusinessProps {
  isBusinessLoading: boolean;
  businessDetails: BusinessProfile;
  businessAddress: Address;
  businessPhoneNumber: string;
  isBusinessError: any;
}

export function getBusiness(): BusinessProps {
  const apiUrl = import.meta.env['VITE_SPICEROAD_URL']
  const { businessId, isErrorParties, isLoadingParties, isRenewal } = useParties();
  const { data, error, isLoading } = useSWR(isRenewal && businessId ? `${apiUrl}/v1/businesses/${businessId}` : null)
  return {
    businessDetails: data,
    businessAddress: findObjWithType(data?.addresses,'PHYSICAL_ADDRESS'),
    businessPhoneNumber: findObjWithType(data?.contacts, 'PHONE_NUMBER')?.value,
    isBusinessLoading: isLoading || isLoadingParties,
    isBusinessError: error || isErrorParties
  }
}

export default getBusiness;
