export const fetcher =  (url, args? ) => {
  const addToken =  args?.token ? {Authorization: `Bearer ${args?.token}`} : args
  try {
    return fetch(url, {
      method: 'GET',
      ...args,
      headers: {
        ...addToken,
        ...args?.unsetContentType ? {} : {...args?.contentType ? args?.contentType : {'Content-Type': 'application/json'}},
      },
    }).then(async (res) => {
      if (!res.ok) {
        throw {
          error: new Error("HTTP status code: " + res?.status),
          messages: res.json().catch(() => res)
        }
      }
      switch (args?.returnType) {
        case 'TEXT': return res.text().catch(() => res)
        case 'JSON': return res.json().catch(() => res);
        case 'RAW': return res
        default : return res.json().catch(() => res);
      }
    })
  } catch (error) {
    return error
  }
};
